import { createApi } from "@reduxjs/toolkit/query/react";
import { Api } from "../index";
import { URL } from "store/urls";

export const UserAddressApi = createApi({
    reducerPath: "UserAddressApi",
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ["UserAddress"],
    endpoints: (build) => ({
        getUserAddress: build.query({
            query: (params) => `${URL.USER_ADDRESS_LIST}?${params}`,
            providesTags: ["LIST"]
        }),
        createUserAddress: build.mutation({
            query: (body) => ({
                url: `${URL.USER_ADDRESS_CREATE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['LIST']
        }),
        updateUserAddress: build.mutation({
            query: (body) => ({
                url: `${URL.USER_ADDRESS_UPDATE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['LIST']
        }),
        deleteUserAddress: build.mutation({
            query: (body) => ({
                url: `${URL.USER_ADDRESS_DELETE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['LIST']
        }),
    })
});

export const {
    useGetUserAddressQuery,
    useCreateUserAddressMutation,
    useUpdateUserAddressMutation,
    useDeleteUserAddressMutation,
} = UserAddressApi;
