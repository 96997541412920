import React from 'react';
import EvaluationUpdateFileInput from './evaluationUpdateFileInput';
import {CustomSubmitButton} from "components/buttons/CustomButton";
import {Box, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";

const EvaluationUpdateTable = ({userCertRate, certCategory, groupCode, isLoading, list, setShowApiModal, sendList, deleteFileSeq, setDeleteFileSeq}) => {
    const renderedCats = [];
    let arr = [];

    const handleList = (e) => {
        if (arr.find(item => item.certCategorySeq === e.certCategorySeq)) {
            arr = arr.map(item =>
                item.certCategorySeq === e.certCategorySeq ? { ...item, files: e.files } : item
            );
        } else {
            arr.push(e);
        }
        sendList([...list, ...arr]);
    };

    const handleDeleteFile = (seq, fileName, delSeq) => {
        const filteredList = list.filter((item) => item.certCategorySeq == seq && item.files[0].name != fileName);
        if(delSeq) {
            setDeleteFileSeq([...deleteFileSeq, delSeq]);
        }
        sendList([...filteredList]);
    };
    
    return (
        <>
            <table>
                <caption>기업등록</caption>
                <tbody>
                <tr>
                    <td className="hdr center">구분</td>
                    <td className="hdr center">세부평가항목</td>
                    <td className="hdr center">증빙자료</td>
                    <td className="hdr center">
                        평가 자료 업로드2단계 ​
                        <br />
                        (PDF 파일 업로드)​​
                    </td>
                </tr>
                {!isLoading && certCategory?.data?.map((item, i) => {
                    if (!renderedCats.includes(item.certCategoryCd)) {
                        renderedCats.push(item.certCategoryCd);
                        const rowCount = certCategory.data.filter((m) => m.certCategoryCd === item.certCategoryCd).length;
                        const categoryCode = groupCode?.data?.find((code) => code.code === item.certCategoryCd)?.codeNm;

                        return (
                            <tr key={i}>
                                {categoryCode && <td rowSpan={rowCount}>{categoryCode}</td>}
                                <td>{item.ratingName}</td>
                                <td>{item.supportingMaterialName} {item.scoreDesc && <span className="bs_green">{item.scoreDesc}</span>}</td>
                                <td>
                                    {
                                        item.ratingName == '기술 인력 교육 및 교육훈련 지원 비율' ? (
                                            <CustomSubmitButton
                                                onClick={() => {
                                                    setShowApiModal(true);
                                                }}
                                                className="step_next_button"
                                                text="국가연구자 정보 불러 오기"
                                                sx={{ fontSize: "14px"}}
                                                disabled={false}
                                            />
                                        ) : (
                                            item.ratingName !== '연구개발 예산 및 투자율' ? (
                                                <EvaluationUpdateFileInput
                                                    item={item}
                                                    data={userCertRate?.filter((cert) => cert.certCategorySeq == item.seq)[0]}
                                                    setData={(e) => {handleList(e)}}
                                                    idx={i}
                                                    deleteFile={(seq, fileName, deleteFileSeq) => handleDeleteFile(seq, fileName, deleteFileSeq)}
                                                />
                                            ) : (
                                                <Grid
                                                    container
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        flexWrap: "nowrap",
                                                        gap: "10px"
                                                    }}
                                                >
                                                    <Grid item sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                        <Box
                                                            sx={{
                                                                border: "2px solid #3c3c6e",
                                                                background: "#E0F8FE",
                                                                borderRadius: "50%",
                                                                minWidth: "30px",
                                                                height: "30px",
                                                                fontSize: "10px",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                marginRight: "10px"
                                                            }}
                                                        >
                                                            {userCertRate?.filter((cert) => cert.certCategorySeq == item.seq)[0]?.attachments[0]?.fileExt}
                                                        </Box>
                                                        <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                                            <Typography>{userCertRate?.filter((cert) => cert.certCategorySeq == item.seq)[0]?.attachments[0]?.fileNm}</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item>
                                                    </Grid>
                                                </Grid>
                                            )
                                        )
                                    }
                                </td>
                            </tr>
                        );
                    } else {
                        return (
                            <tr key={i}>
                                <td>{item.ratingName}</td>
                                <td>{item.supportingMaterialName} {item.scoreDesc && <span className="bs_green">{item.scoreDesc}</span>}</td>
                                <td>
                                    {
                                        item.ratingName == '기술 인력 교육 및 교육훈련 지원 비율' ? (
                                            <CustomSubmitButton
                                                onClick={() => {
                                                    setShowApiModal(true);
                                                }}
                                                className="step_next_button"
                                                text="국가연구자 정보 불러 오기"
                                                sx={{ fontSize: "14px"}}
                                                disabled={false}
                                            />
                                        ) : (
                                            item.ratingName !== '연구개발 예산 및 투자율' ? (
                                                <EvaluationUpdateFileInput
                                                    item={item}
                                                    data={userCertRate?.filter((cert) => cert.certCategorySeq == item.seq)[0]}
                                                    setData={(e) => {handleList(e)}}
                                                    idx={i}
                                                    deleteFile={(seq, fileName, deleteFileSeq) => handleDeleteFile(seq, fileName, deleteFileSeq)}
                                                />
                                            ) : (
                                                ''
                                            )
                                        )
                                    }
                                </td>
                            </tr>
                        );
                    }
                })}
                </tbody>
            </table>
        </>
    );
};

export default EvaluationUpdateTable;
