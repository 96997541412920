import React from "react";
import { Button } from "@mui/material";
import AnimateButton from "components/extended/AnimateButton";

const ProjectButton = ({ size, text, loading, onClick, ...props }) => {
    return (
        <AnimateButton sx={{ width: "100%" }}>
            <Button
                disableElevation
                fullWidth
                size="small"
                variant="contained"
                onClick={onClick}
                disabled={loading || false}
                sx={{
                    marginTop: props?.marginTop ? props?.marginTop : '0',
                    background: props?.bcolor ? props?.bcolor : "#503795",
                    width: props?.width ? props?.width : "208px",
                    color: props.color ? props.color : "#fff",
                    border: `1px solid ${props?.bcolor ? props?.bcolor : "#503795"}`,
                    borderRadius: props?.borderRadius ? props?.borderRadius : "12px",
                    height: props?.height ? props?.height : "45px",
                    fontSize: props?.fontSize ? props?.fontSize : "13px",
                    fontWeight: props?.fontWeight ? props?.fontWeight : "unset",
                    ":hover": {
                        backgroundColor: props?.hcolor ? props?.hcolor : "#572295"
                    },
                    "&.Mui-disabled": {
                        borderColor: props?.hcolor ? props?.hcolor : "#e0e0e0"
                    },
                    ...props.sx
                }}
            >
                {text}
            </Button>
        </AnimateButton>
    );
};
export default ProjectButton;
