import { createSlice } from "@reduxjs/toolkit";
import ObjectUtil from "utils/ObjectUtil";

export const AuthenticationSlice = createSlice({
    name: "authentication",
    initialState: {
        isOpenSignIn: false,
        isOpenSignUp: false,
        user: localStorage.getItem("user") === null ? null : ObjectUtil.safeJsonParse(localStorage.getItem("user"))
    },
    reducers: {
        openSignIn: (state) => {
            state.isOpenSignIn = true;
        },
        closeSignIn: (state) => {
            state.isOpenSignIn = false;
        },
        openSignUp: (state) => {
            state.isOpenSignUp = true;
        },
        closeSignUp: (state) => {
            state.isOpenSignUp = false;
        },
        storeUser: (state, action) => {
            if (action.payload !== undefined && action.payload !== null) {
                state.user = action.payload;
                localStorage.setItem("user", ObjectUtil.safeJsonStringify(action.payload));
            } else if (action.payload === null) {
                state.user = null;
                localStorage.removeItem("user");
            }
        },
        refreshUserFromLocalStorage: (state) => {
            const userFromLocalStorage = localStorage.getItem("user");
            state.user = userFromLocalStorage ? ObjectUtil.safeJsonParse(userFromLocalStorage) : null;
        }
    }
});

export const { openSignIn, closeSignIn, openSignUp, closeSignUp, storeUser, refreshUserFromLocalStorage } = AuthenticationSlice.actions;
export default AuthenticationSlice.reducer;
