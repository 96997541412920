import React, { lazy } from "react";
import Loadable from "components/Loadable";
import { ApplicationApi } from "api/application/index";
const Paying = Loadable(lazy(() => import("./paying")));

const PaymentConfig = {
    routes: [
        {
            path: "",
            children: [
                {
                    path: "paying",
                    element: <Paying />
                }
            ]
        }
    ],
    API: ApplicationApi
};

export default PaymentConfig;
