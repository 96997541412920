import React, { lazy } from "react";
import { AuthApi } from "api/auth/auth";
import Application from "../application/application";
import EvaluationDataForm from "./evaluationData/evaluationDataForm";
import EligibilityForm from "./eligibility/eligibilityForm";
import Paying from "../payment/paying";
import Loadable from "components/Loadable";
const FinancialEdit = Loadable(lazy(() => import("../application/edit")));
const SelfDiagnosisEdit = Loadable(lazy(() => import("../selfDiagnosis/edit")));
const CertOrder = Loadable(lazy(() => import("../certOrder/certOrder")));
const SelfResult = Loadable(lazy(() => import("../selfDiagnosis/selfResult")));

const MyPageConfig = {
    routes: [
        {
            path: "mypage",
            children: [
                {
                    path: "application",
                    element: <Application />
                },
                {
                    path: "selfdiagnosis",
                    element: <SelfDiagnosisEdit />
                },
                {
                    path: "selfresult",
                    element: <SelfResult />
                },
                {
                    path: "financial",
                    element: <FinancialEdit />
                },
                {
                    path: "evaluation",
                    element: <EvaluationDataForm />
                },
                {
                    path: "paying",
                    element: <Paying />
                },
                {
                    path: "eligibility",
                    element: <EligibilityForm />
                },
                {
                    path: "certificate",
                    element: <CertOrder />
                }
            ]
        }
    ],
    API: AuthApi
};

export default MyPageConfig;
