import React, { forwardRef, useEffect, useState } from "react";
import { Box, FormHelperText, OutlinedInput, Skeleton, Typography, InputAdornment, IconButton } from "@mui/material";
import { addComma } from "utils/functions/common";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const TextInputField = forwardRef(
    (
        {
            sx,
            isLoading,
            placeholder,
            onChange,
            onBlur,
            error,
            width,
            moWidth,
            moHeight,
            height,
            size,
            label,
            type,
            disabled,
            value,
            textAlign,
            trim,
            ...props
        },
        ref
    ) => {
        const [val, setVal] = useState("");
        const [showPassword, setShowPassword] = useState(false);
        const handleClickShowPassword = () => setShowPassword((show) => !show);
        const [isEmailValid, setIsEmailValid] = useState(true);

        useEffect(() => {
            if (type === "money" && val) {
                setVal(addComma(val));
            } else {
                setVal(val || "");
            }
        }, [type, val]);

        useEffect(() => {
            if (value === 0) {
                setVal("");
            } else {
                setVal(value);
            }
        }, [value, type]);

        const handleChange = (e) => {
            const { value } = e.target;
            let formattedValue = value;

            if (type === "money") {
                formattedValue = value.replace(/\D/g, "");
                formattedValue = formattedValue === "" ? "0" : addComma(formattedValue);
            } else if (type === "number") {
                formattedValue = value.replace(/\D/g, "");
            } else if (type === "text") {
                formattedValue = value.replace(/[^ㄱ-ㅎ가-힣a-zA-Z]/g, "");
            } else if (type === "email") {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                setIsEmailValid(emailPattern.test(value));
            }

            if (trim === "true") {
                formattedValue = formattedValue.trim();
            }

            setVal(formattedValue);

            if (onChange) {
                onChange({ ...e, target: { ...e.target, value: formattedValue } });
            }
        };

        return isLoading ? (
            <Skeleton animation="wave" width={width || "200px"} height={height || "25px"} />
        ) : (
            <Box
                width={width || "100%"}
                textAlign={"start"}
                size="small"
                sx={{
                    "@media (max-width: 575.98px)": {
                        width: moWidth ? moWidth : "100%",
                        height: moHeight ? moHeight : "35px"
                    }
                }}
            >
                {label && <Typography>{label}</Typography>}
                <OutlinedInput
                    ref={ref}
                    type={type === "password" && !showPassword ? "password" : "text"}
                    error={!!error}
                    onChange={handleChange}
                    value={val || ""}
                    size={size || "small"}
                    placeholder={type === "money" && val === "0" ? "0" : val === "" ? placeholder : ""}
                    onBlur={onBlur}
                    sx={{
                        width: width ? width : "100%",
                        height: height ? height : "40px",
                        "@media (max-width: 575.98px)": {
                            width: moWidth ? moWidth : "100%",
                            height: moHeight ? moHeight : "35px"
                        },
                        ...sx
                    }}
                    inputProps={{
                        maxLength: props?.maxLength || 255,
                        style: { textAlign: type === "money" ? "end" : textAlign, borderColor: error ? "red" : "" }
                    }}
                    endAdornment={
                        type === "password" && (
                            <InputAdornment position="end" sx={{ right: "10px" }}>
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={(event) => event.preventDefault()}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }
                    disabled={disabled === "true" || disabled === true}
                    {...props}
                />
                {error && <FormHelperText error>{error}</FormHelperText>}
                {type === "email" && !isEmailValid && <FormHelperText error>이메일 형식이 올바르지 않습니다.</FormHelperText>}
            </Box>
        );
    }
);

export default TextInputField;
