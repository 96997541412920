import React, {useState} from 'react';
import {MultipleFileUploader} from "components/useForm";

const EligibilityCreateFileInput = ({item, data, setObj, deleteFile }) => {
    let fileList = [];
    const attachments = data?.attachments || data || [];
    const handleFileInput = () => {
        const obj = {
            seq: data && data?.seq || 0,
            certCategorySeq: item.seq,
            stageType: 2,
            fileGroupSeq: data && data?.fileGroupSeq || 0,
            files: [...fileList]
        };
        setObj(obj);
    };
    return (
        <>
            <MultipleFileUploader
                name={'file' + item.seq}
                onChange={(files) => {
                    fileList.push(...files);
                    handleFileInput();
                }}
                deleteFile={(fileName, seq) => {
                    deleteFile(item.seq, fileName, seq);
                }}
                attachments={[...attachments]}
                maxLength={6}
                inputProps={{ accept: '' }}
                isLoading={false}
            />
        </>
    )
};

export default EligibilityCreateFileInput;

