import React, { forwardRef } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, FormHelperText, Skeleton, TextField } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

dayjs.locale("ko");

const DefaultDateInput = forwardRef(({ error, disablePast, disableFuture, ...props }, ref) => {
    return props.loading ? (
        <Skeleton animation="wave" height={60} sx={{ borderRadius: "12px" }} />
    ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs} locale="ko">
            <Box width={"100%"}>
                <DatePicker
                    ref={ref}
                    name={props.name}
                    inputFormat="YYYY-MM-DD"
                    format={"YYYY-MM-DD"}
                    disabled={props?.disabled || false}
                    views={["year", "month", "day"]}
                    defaultValue={props?.value}
                    value={props?.value}
                    onChange={(date) => {
                        if (date) {
                            const formattedDate = dayjs(date).format("YYYY-MM-DD");
                            props.onChange(formattedDate);
                        } else {
                            props.onChange("");
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            sx={{ width: { md: props?.width ? props?.width : "200px" }, fontSize: "16px" }}
                            size="small"
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                readOnly: props?.readOnly ?? false
                            }}
                        />
                    )}
                    disablePast={disablePast}
                    disableFuture={disableFuture}
                />
                <FormHelperText error>{error}</FormHelperText>
            </Box>
        </LocalizationProvider>
    );
});

export default DefaultDateInput;
