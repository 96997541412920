import React from 'react';
import { styled } from '@mui/system';
import Button from './Button';
import { CircularProgress } from '@mui/material';

// Style 
import { ButtonStyle } from "./style";

const BaseButton = styled(Button)({});

const SaveButton = ({ size, text, loading, onClick, ...props }) => {

    return (
        <>
            <BaseButton
                type="submit"
                variant="outlined"
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} color="light" /> : ""}
                onClick={onClick}
                {...props}
                size={size}
                sx={ButtonStyle.saveBtn}
            >
                {text}
            </BaseButton>
        </>
    );
};
export default SaveButton;
