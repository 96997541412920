import React from 'react';
import { Controller } from 'react-hook-form';
import { DefaultSelect } from './default';

const Select = ({ name, options, control, loading, ...props }) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <DefaultSelect
                    error={error}
                    options={options && options?.length > 0 ? options : []}
                    {...field}
                    {...props}
                    loading={loading}
                />
            )}
        />
    );
};

export default Select;
