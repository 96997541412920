import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Title = styled.div`
    font-size: 120px;
    font-weight: 700;
    color: #333;

    @media (max-width: 600px) {
        font-size: 60px;
    }
`;
const Descr = styled.p`
    margin-top: 20px;
    font-size: 24px;
    color: #555;
    text-align: center;
    max-width: 80%;
    line-height: 1.5;
    @media (max-width: 600px) {
        font-size: 18px;
    }
`;

const NotFound = () => {
    return (
        <div
            style={{
                width: "100%",
                padding: "80px 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f5f5f5"
            }}
        >
            <Title>
                4<span style={{ color: "#e74c3c" }}>0</span>4
            </Title>
            <Descr>
                죄송합니다. <br /> 요청하신 페이지를 찾을 수 없습니다.
            </Descr>
            <Link
                to="/"
                style={{
                    marginTop: "40px",
                    backgroundColor: "#3498db",
                    color: "white",
                    padding: "12px 24px",
                    borderRadius: "30px",
                    textDecoration: "none",
                    fontSize: "18px",
                    transition: "background-color 0.3s"
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = "#2980b9")}
                onMouseOut={(e) => (e.target.style.backgroundColor = "#3498db")}
            >
                홈으로 돌아가기
            </Link>
        </div>
    );
};

export default NotFound;
