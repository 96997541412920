import React, { lazy } from "react";
import Loadable from "components/Loadable";
import { ApplicationApi } from "api/application";
const SelfDiagnosis = Loadable(lazy(() => import("./selfDiagnosis")));
const SelfResult = Loadable(lazy(() => import("./selfResult")));

const selfDiagnosisConfig = {
    routes: [
        {
            path: "",
            children: [
                {
                    path: "selfdiagnosis",
                    element: <SelfDiagnosis />
                },
                {
                    path: "selfresult",
                    element: <SelfResult />
                }
            ]
        }
    ],
    API: ApplicationApi
};

export default selfDiagnosisConfig;
