import React, { useEffect, useState } from "react";
import { SERVER_URL } from "config";
import { Link } from "react-router-dom";
import SUB_BANNER from "assets/imgs/sub/sub_banner.jpg";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
const SubMenu = ({ menuData, location }) => {
    const currentLanguage = i18next.language;
    const { t } = useTranslation(); // can't delete it
    const regex = /\/(\d+)$/;
    const stringsToRemove = ["/create", "/detail", "/view"];
    const regexPattern = new RegExp(stringsToRemove.join("|"), "gi");

    let locationPath = location.pathname;
    const [subMenus, setSubMenus] = useState([]);

    if (!locationPath.startsWith("/web/contents")) {
        locationPath = locationPath.replace(regex, "").replace(regexPattern, "");
    }

    useEffect(() => {
        if (menuData) {
            let currentSubMenus = menuData?.data?.filter((item) => item.menuUrl == locationPath && item.parentSeq != 0);

            if (currentSubMenus.length > 0) {
                setSubMenus(menuData?.data?.filter((item) => item.parentSeq == currentSubMenus[0].parentSeq));
            }
        }
    }, [location]);

    return (
        <>
            <div className="subBanner">
                {subMenus && subMenus.length > 0 ? (
                    subMenus.map(
                        (item, i) =>
                            locationPath === item.menuUrl && (
                                <div
                                    key={i}
                                    className="sub_banner_image"
                                    style={{
                                        backgroundImage: `url(${item.attachment?.url ? SERVER_URL + item.attachment.url : "SUB_BANNER"})`
                                    }}
                                >
                                    <div className="sub_banner_text sub_container">
                                        <div className="inn">
                                            <p>{item.menuName}</p>
                                            <h1>{item.menuDescEn}</h1>
                                        </div>
                                    </div>
                                </div>
                            )
                    )
                ) : (
                    <div
                        className="sub_banner_image"
                        style={{
                            backgroundImage: `url(${SUB_BANNER})`
                        }}
                    >
                        <h1>
                            {(locationPath === "/join" ||
                                locationPath === "/application" ||
                                locationPath === "/financial" ||
                                locationPath === "/selfdiagnosis" ||
                                locationPath === "/selfresult" ||
                                locationPath === "/paying" ||
                                locationPath === "/evaluation") &&
                                "신규신청"}
                            {(locationPath === "/mypage/application" ||
                                locationPath === "/mypage/financial" ||
                                locationPath === "/mypage/selfdiagnosis" ||
                                locationPath === "/mypage/evaluation" ||
                                locationPath === "/mypage/eligibility" ||
                                locationPath === "/mypage/paying" ||
                                locationPath === "/mypage/certificate") &&
                                "마이페이지"}
                            {locationPath === "/login" && "로그인"}
                        </h1>
                    </div>
                )}
            </div>
            {subMenus.length > 1 ? (
                <div className="head-conts2">
                    <nav className="sub-menu2">
                        <ul>
                            {subMenus.map((item, index) => (
                                <li key={index} style={{ borderRight: index + 1 != subMenus.length ? "1px solid var(--grey)" : "none" }}>
                                    <Link className={locationPath == item.menuUrl ? "active" : ""} to={item.menuUrl}>
                                        {currentLanguage === "en" ? item.menuName : item.menuNameKr}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default SubMenu;
