import React from "react";
import { Box, Table as MuiTable, TableBody } from "@mui/material";

const Table = ({ children, reqtext, ...props }) => {
    return (
        <MuiTable {...props}>
            <TableBody style={{ borderTop: '1px solid #B7B7B7' }}>{children}</TableBody>
        </MuiTable>
    );
};

export default Table;
