import React, {useState} from 'react';
import {MultipleFileUploader} from "components/useForm";

const EvaluationUpdateFileInput = ({item, data, setData, deleteFile }) => {
    let fileList = [];
    const handleFileInput = () => {

        const obj = {
            seq: data && data?.seq || 0,
            certCategorySeq: item.seq,
            stageType: 1,
            fileGroupSeq: data && data.fileGroupSeq || 0,
            files: [...fileList]
        };
        setData(obj);
    };

    return (
        <>
            <MultipleFileUploader
                name={'file' + item.seq}
                onChange={(files) => {
                    fileList.push(...files);
                    handleFileInput();
                }}
                deleteFile={(fileName, seq) => {
                    deleteFile(item.seq, fileName, seq);
                }}
                attachments={data?.attachments || []}
                maxLength={6}
                inputProps={{ accept: '' }}
                isLoading={false}
            />
        </>
    )
};

export default EvaluationUpdateFileInput;

