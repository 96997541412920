import { createApi } from "@reduxjs/toolkit/query/react";
import { Api } from "../index";
import { URL } from "store/urls";

export const OrderApi = createApi({
    reducerPath: "OrderApi",
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ["Orders"],
    endpoints: (build) => ({
        // getPaymentDetail: build.query({
        //     query: () => `${URL.PAYMENT_DETAIL}`,
        //     providesTags: ["PAYMENT"]
        // }),
        createOrder: build.mutation({
            query: (body) => ({
                url: `${URL.ORDER_CREATE}`,
                method: "POST",
                body
            })
        })
    })
});

export const { useCreateOrderMutation } = OrderApi;
