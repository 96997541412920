import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const getSizeStyles = (size) => {
    const sizes = {
        sm: { width: "100px", height: "40px", fontSize: "14px" },
        md: { width: "150px", height: "40px", fontSize: "14px" },
        lg: { width: "280px", height: "62px", fontSize: "18px" },
        xl: { width: "300px", height: "72px", fontSize: "26px" }
    };

    return sizes[size] || {};
};

export const CustomButton = ({ link, text, size, className = "", onClick }) => {
    const style = {
        width: "200px",
        height: "52px",
        fontSize: "18px",
        lineHeight: "1",
        ...getSizeStyles(size)
    };

    return (
        <>
            <Link to={link} className={`custom_button ${className}`} onClick={onClick} style={style}>
                {text}
            </Link>
        </>
    );
};

export const CustomAtagButton = ({ link, text, size, isBlank }) => {
    const style = {
        width: "200px",
        height: "52px",
        fontSize: "18px",
        lineHeight: "1",
        ...getSizeStyles(size)
    };

    return (
        <>
            <a href={link} className="custom_button" style={style} target={isBlank ? "_blank" : ""} rel="noopener noreferrer">
                {text}
            </a>
        </>
    );
};

export const CustomSubmitButton = ({ className = "", text, size, onClick, disabled, loading, type, sx }) => {
    const style = {
        width: "200px",
        height: "52px",
        fontSize: "18px",
        lineHeight: "1",
        ...getSizeStyles(size),
        ...sx
    };

    return (
        <>
            <button type={type} onClick={onClick} className={`custom_button ${className}`} style={style} disabled={disabled || loading}>
                {loading && <CircularProgress size={20} />} {text}
            </button>
        </>
    );
};
