import { fontWeight } from "@mui/system";

export const ButtonStyle = {
    mainButton: {
        background: "#F27D5B",
        color: "#ffff",
        borderColor: "#F27D5B",
        fontSize: "18px",
        fontWeight: "500",
        "&:hover": {
            background: "#f26036",
            color: "#fff",
            borderColor: "#f26036"
        }
    },
    saveBtn: {
        borderRadius: "0",
        background: "#333333",
        color: "#fff",
        borderColor: "#333333",
        width: "100px",
        "&:hover": {
            background: "#333333",
            color: "#fff",
            borderColor: "#333333"
        }
    },
    closeBtn: {
        backgroundColor: "rgb(243, 243, 243)",
        borderColor: "rgb(221, 221, 221)",
        color: "rgb(68, 68, 68)",
        "&:hover": {
            background: "#fff",
            color: "#000",
            borderColor: "rgba(51,51,51,0.72)"
        }
    }
};
