export const SERVER_URL = process.env.REACT_APP_TAGO_WEB_CONTEXT_URL; // REST Index 서버 Domain Pages

export const AVAIL_LANG = [
    { code: "kr", name: "한국어" },
    { code: "en", name: "English" }
];

export const config = {
    basename: "/",
    defaultPath: "/",
    fontFamily: `'Pretendard', sans-serif`,
    borderRadius: 12
};
