import React, { useState, useEffect } from "react";
import apiData from "api/api-data.json";

const IndustryTable = ({ code, setCode, title, type }) => {
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        setSelectedRow(null);
    }, [code]);

    const handleRowClick = (item) => {
        setSelectedRow(item.classCd);
        if (type === 1) {
            setCode(item.classCd);
        } else {
            setCode({ code: item.classCd, name: item.classCdNm });
        }
    };

    const renderRows = (data) => {
        return data.map((item, index) => (
            <tr key={index} onClick={() => handleRowClick(item)} className={`hoverAbleRow ${selectedRow === item.classCd ? "active" : ""}`}>
                <td className="center">{item?.classCd}</td>
                <td className="center">{item?.classCdNm}</td>
            </tr>
        ));
    };

    let filteredData;
    if (type === 1) {
        filteredData = apiData.dataset.filter((item) => item.kindCd === item.upperClassCd);
    } else if (type === 2 && code) {
        filteredData = apiData.dataset.filter((item) => item.upperClassCd === (typeof code === "string" ? code : code.code));
    } else if (code) {
        filteredData = apiData.dataset.filter((item) => item.upperClassCd === code?.code);
    } else {
        filteredData = [];
    }

    return (
        <div style={{ width: "100%" }}>
            <div style={{ marginBottom: "20px" }}>{title}</div>
            <div style={{ width: "100%", maxHeight: "520px", overflowY: "auto" }}>
                <table style={{ width: "100%" }}>
                    <tbody>
                        <tr>
                            <td className="center hdr">중분류</td>
                            <td className="center hdr">업종명</td>
                        </tr>
                        {renderRows(filteredData)}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default React.memo(IndustryTable);
