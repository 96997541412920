import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const MyPageSideMenu = () => {
    const navigate = useNavigate();

    const menus = [
        {
            name: "기본정보",
            link: "/mypage/application"
        },
        {
            name: "재무/일반 자료",
            link: "/mypage/financial"
        },
        {
            name: "자가진단",
            link: "/mypage/selfdiagnosis"
        },
        {
            name: "1단계 평가",
            link: "/mypage/evaluation"
        },
        {
            name: "2단계 평가",
            link: "/mypage/eligibility"
        },
        {
            name: "인증서 발급",
            link: "/mypage/certificate"
        }
    ];

    return (
        <div className="mypage_side_menu">
            <h4 className="side_menu_tit">마이페이지</h4>
            <ul className="side_menu_pc">
                {menus?.map((menu, i) => (
                    <li key={i + "_side"}>
                        <NavLink to={menu?.link} activeclassname="active">
                            {menu?.name}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};
export default MyPageSideMenu;
