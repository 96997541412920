import React, { createContext, useContext, useState } from "react";

const NewContext = createContext({
    page: 0, size: 5, searchWrd: "" 
});

export const NewProvider = ({ children }) => {
    const [options, setOptions] = useState({
        page: 0, size: 5, searchWrd: "" 
    });

    return <NewContext.Provider value={{ options, setOptions }}>{children}</NewContext.Provider>;
};

export const useNewContext = () => useContext(NewContext);

