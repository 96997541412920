import React, {forwardRef} from "react";
import {Controller} from "react-hook-form";
import CustomTextInputField from "./default/CustomTextInputField";

const CustomTextInput = forwardRef(({ error, ...props }, ref) => {
    return (
        <Controller
            ref={ref}
            name={props?.name}
            control={props?.control}
            render={({ field }) => (
                <CustomTextInputField
                    type={props?.type}
                    value={field?.value}
                    placeholder={props?.placeholder}
                    error={error}
                    {...field}
                    {...props}
                />
            )}
        />
    );
});

export default CustomTextInput;
