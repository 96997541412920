import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from "../index";
import { URL } from "store/urls";

export const CertCategoryApi = createApi({
    reducerPath: 'CertCategoryApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['CertCategories'],
    endpoints: (build) => ({
        getCertCategoriesByType: build.query({
            query: (params) => `${URL.CERT_CATEGORY_TYPE_LIST}?${params}`,
            providesTags: ['LIST_TYPE']
        })
    })
});

export const {
    useGetCertCategoriesByTypeQuery,
} = CertCategoryApi;
