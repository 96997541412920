import React, { createContext, useContext, useState } from "react";

const EventContext = createContext({ page: 0, size: 5, searchWrd: "" });

export const EventProvider = ({ children }) => {
    const [options, setOptions] = useState({ page: 0, size: 5, searchWrd: "" });

    return <EventContext.Provider value={{ options, setOptions }}>{children}</EventContext.Provider>;
};

export const useEventContext = () => useContext(EventContext);

