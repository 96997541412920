const ValidationTerms = (terms) => {
    if (terms.termsChecked01 === "no") {
        return "서비스를 이용하시기위해서 약관에 동의하셔야 합니다.";
    }
    if (terms.termsChecked02 === "no") {
        return "서비스를 이용하시기위해서 개인정보 수집에 동의하셔야 합니다";
    }
    if (terms.termsChecked03 === "no") {
        return "서비스를 이용하시기위해서 개인정보 수집(대표자)에 동의하셔야 합니다.";
    }
    if (terms.termsChecked04 === "no") {
        return "서비스를 이용하시기위해서 개인정보 수집(담당자)에 동의하셔야 합니다.";
    }
    if (terms.termsChecked05 === "no") {
        return "서비스를 이용하시기위해서 기술사업계획서 정보 공개에 동의하셔야 합니다.";
    }
    if (terms.termsChecked06 === "no") {
        return "서비스를 이용하시기위해서 과학기술혁신기업 정보 공시에 동의하셔야 합니다.";
    }
    // if (terms.termsChecked07 === "no") {
    //     return "과학기술혁신기업 협회 정책정보 알림 서비스";
    // }
    if (terms.termsChecked08 === "no") {
        return "2년이상의 기업 아닐 경우, 신청이 불가능합니다.";
    }
    if (terms.termsChecked09 === "no") {
        return "과학기술인 등록번호(www.ntis.go.kr) 또는 부설기술연구소(www.md.or.kr) 등록되지 아닐 경우, 신청이 불가능합니다.";
    }
};

const ValidationApplication = (
    values,
    nameCheckResult,
    ceoEmailCheckResult,
    writerEmailCheckResult,
    user,
    ceoEmailChanged,
    writerEmailChanged
) => {
    const phoneMiddle = values.phoneMiddle || "";
    const phoneEnd = values.phoneEnd || "";
    const faxMiddle = values.faxMiddle || "";
    const faxEnd = values.faxEnd || "";
    const ceoPhoneMiddle = values.ceoPhoneMiddle || "";
    const ceoPhoneEnd = values.ceoPhoneEnd || "";

    if (values.coName.trim() === "") {
        return "업체명을 입력하여 주세요.";
    }
    if (!user && !nameCheckResult) {
        return "사업자등록번호 중복확인 해 주세요.";
    }
    if (values.ceoName.trim() === "") {
        return "대표자성명을 입력하여 주세요.";
    }
    if (values.ceoBirth.trim() === "") {
        return "대표자생년월일을 입력하여 주세요.";
    }
    if (values.ceoEmail.trim() === "") {
        return "대표자 이메일을 입력하여 주세요.";
    }
    if (ceoEmailChanged && !ceoEmailCheckResult) {
        return "대표자 이메일을 중복확인 해 주세요.";
    }
    if (!values.ceoPhoneStart || ceoPhoneMiddle.trim() === "" || ceoPhoneEnd.trim() === "") {
        return "대표자 핸드폰번호를 입력하여 주세요.";
    }
    if (values.writerName.trim() === "") {
        return "담당자명을 입력하여 주세요.";
    }
    if (values.writerEmail.trim() === "") {
        return "담당자 이메일을 입력하여 주세요.";
    }
    if (writerEmailChanged && !writerEmailCheckResult) {
        return "담당자 이메일을 중복확인 해 주세요.";
    }
    if (!values.coDate) {
        return "설립일을 입력하여 주세요.";
    }
    if (!values.phoneStart || phoneMiddle.trim() === "" || phoneEnd.trim() === "") {
        return "전화번호를 입력하여 주세요.";
    }
    if (!values.faxStart || faxMiddle.trim() === "" || faxEnd.trim() === "") {
        return "FAX를 입력하여 주세요.";
    }
    if (values.coZip.trim() === "") {
        return "주소를 입력하여 주세요.";
    }
    if (values.coAddr2.trim() === "") {
        return "상세 주소를 입력하여 주세요.";
    }
    if (values.coUrl.trim() === "") {
        return "홈페이지 주소를 입력하여 주세요.";
    }
    if (!values.coBaseMoney) {
        return "자본금을 입력하여 주세요.";
    }
    if (!values.coCategory) {
        return "관련업종을 선택하여 주세요.";
    }
    if (!values.accountMonth) {
        return "결산월을 선택하여 주세요.";
    }

    return "";
};
const ValidationFinancial = (values, attchFile) => {
    // if (!values.fnSales || !values.fnSales1) {
    //     return "매출액을 입력하여 주세요.";
    // }
    // if (!values.fnYudun || !values.fnYudun1) {
    //     return "유동자산을 입력하여 주세요.";
    // }
    // if (!values.fnJego || !values.fnJego1) {
    //     return "재고자산을 입력하여 주세요.";
    // }
    // if (!values.fnYudunPuche || !values.fnYudunPuche1) {
    //     return "유동부채를 입력하여 주세요.";
    // }
    // if (!values.fnTotalAsset || !values.fnTotalAsset1) {
    //     return "총자산을 입력하여 주세요.";
    // }
    // if (!values.fnNetProfit || !values.fnNetProfit1) {
    //     return "당기수익을 입력하여 주세요.";
    // }
    // if (!values.fnResearchFunds || !values.fnResearchFunds1) {
    //     return "경상개발비.연구비를 입력하여 주세요.";
    // }
    // if (!values.fnTotalEmployee || !values.fnTotalEmployee1) {
    //     return "총 종업원수를 입력하여 주세요.";
    // }
    // if (!values.fnResearcher || !values.fnResearcher1) {
    //     return "연구인력을 입력하여 주세요.";
    // }
    if (attchFile.data == undefined || attchFile.data == null) {
        return "표준재무제표 파일 업로드하여 주세요.";
    }

    return "";
};
const ValidationSelfdiagnosis = (values) => {};
const ValidationEvaluationData = (values) => {
    return "";
};

export { ValidationApplication, ValidationTerms, ValidationFinancial, ValidationEvaluationData, ValidationSelfdiagnosis };
