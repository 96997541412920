import { createApi } from "@reduxjs/toolkit/query/react";
import { Api } from "../index";
import { URL } from "store/urls";

export const PaymentApi = createApi({
    reducerPath: "PaymentApi",
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ["Payments"],
    endpoints: (build) => ({
        getPaymentDetail: build.query({
            query: () => `${URL.PAYMENT_DETAIL}`,
            providesTags: ["PAYMENT"]
        })
    })
});

export const { useGetPaymentDetailQuery } = PaymentApi;
