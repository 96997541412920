import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { CustomButton, CustomSubmitButton } from "components/buttons/CustomButton";
import { useGetCommonFilterCodeQuery } from "api/commonCode";
import { ConfirmModal } from "components/index";
import AlertModal from "components/modal/AlertModal";
import Steps from "../../../pages/step/steps";
import { useGetCertCategoriesByTypeQuery } from "api/cert/certCategory";
import { useGetUserInfoQuery } from "api/auth/auth";
import {
    useGetUserCertRateQuery,
    useCreateUserCertRateBulkMutation,
    useUpdateUserCertRateBulkMutation,
    useGetUserCompanyMemberListQuery,
    useGetSelfDiagnosisDetailQuery
} from "api/application/index";
import { decodeBase64, reduceByCertCategorySeqAndStageType, xmlToJson } from "utils/functions/common";

import { CustomModal } from "components";
import EvaluationCreateTable from "./evaluationCreateTable";
import EvaluationUpdateTable from "./evaluationUpdateTable";
import { Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { SERVER_URL } from "config";
import { customRequestAPI } from "api/requestApi";
import Loader from "components/Loader";
import { parseJwt } from "utils/functions/common";
import {useSelector} from "react-redux";

const EvaluationDataForm = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.authentication.user);
    const { data: certCategory1, isCertCategory1Loading } = useGetCertCategoriesByTypeQuery("stageType=1");
    const { data: userCertRate, isUserCertRateLoading } = useGetUserCertRateQuery();
    const { data: userInfo, isUserInfoLoading } = useGetUserInfoQuery();
    const { data: userCompanyMembers, isUserCompanyMemberLoading } = useGetUserCompanyMemberListQuery();
    const { data: groupCode, isLoading: groupIsLoading } = useGetCommonFilterCodeQuery("CERT_CATEGORY_CD");
    const { data: selfData, isLoading: selfIsLoading } = useGetSelfDiagnosisDetailQuery(parseJwt(user)?.username);
    const [onAction, resultAction] = useCreateUserCertRateBulkMutation();
    const [onUpdateAction, resultUpdateAction] = useUpdateUserCertRateBulkMutation();
    const [dataYn, setDataYn] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showApiModal, setShowApiModal] = useState(false);
    const [alertModalContent, setAlertModalContent] = useState("");
    const [workerCount, setWorkerCount] = useState(0);
    const [deleteFileSeq, setDeleteFileSeq] = useState([]);
    const [list, setList] = useState([]);
    const [apiList, setApiList] = useState([{}, {}, {}]);
    const [point, setPoint] = useState(0);

    useEffect(() => {
        if (!selfIsLoading && (selfData?.data == null || selfData?.data?.isPass === "N")) {
            setShowAlertModal(true);
        }

        if (userCertRate?.content?.length > 0) {
            setDataYn(true);
            setPoint(
                userCertRate?.content
                    ?.filter((item) => item.stageType === 1)
                    ?.reduce((sum, item) => parseInt(sum) + parseInt(item.sectionPoint), 0)
            );
        }
        
        if (userInfo?.content) {
            setWorkerCount(userInfo?.content?.workerCount || 0);
        }
    }, [userCertRate, userInfo, navigate, selfData]);

    const submit = useCallback(() => {
        let formList = reduceByCertCategorySeqAndStageType(list);
        let formData = new FormData();
        for (let i = 0; i < certCategory1?.data?.length; i++) {
            let item = formList.find((item) => item.certCategorySeq === certCategory1?.data[i]?.seq);
            if (item) {
                formData.append("form[" + i + "].seq", item.seq || 0);
                formData.append("form[" + i + "].certCategorySeq", item.certCategorySeq);
                formData.append("form[" + i + "].stageType", item.stageType);
                formData.append("form[" + i + "].fileGroupSeq", item.fileGroupSeq);
                for (let j = 0; j < item.files?.length; j++) {
                    formData.append("form[" + i + "].files[" + j + "]", item.files[j]);
                }
            } else {
                formData.append("form[" + i + "].certCategorySeq", certCategory1?.data[i]?.seq);
                formData.append("form[" + i + "].stageType", certCategory1?.data[i]?.stageType);
            }
        }
        for (let i = 0; i < deleteFileSeq.length; i++) {
            formData.append("deleteFileSeq[" + i + "]", deleteFileSeq[i]);
        }
        let idx = 0;
        for (let i = 0; i < apiList.length; i++) {
            if (apiList[i].memberName && apiList[i].nationalResearchNum) {
                formData.append("companyMemberList[" + idx + "].memberName", apiList[i].memberName);
                formData.append("companyMemberList[" + idx + "].nationalResearchNum", apiList[i].nationalResearchNum);
                formData.append("companyMemberList[" + idx + "].birthday", apiList[i].birthday);
                formData.append("companyMemberList[" + idx + "].institutionName", apiList[i].institutionName);
                formData.append("companyMemberList[" + idx + "].position", apiList[i].position);
                formData.append("companyMemberList[" + idx + "].mobileNum", apiList[i].mobileNum);
                idx++;
            }
        }

        formData.append("workerCount", workerCount || 0);

        if (dataYn) {
            onUpdateAction(formData)
                .then((res) => {
                    setShowConfirmModal(false);
                    if (res.data.status === 200) {
                        navigate("/mypage/paying");
                    } else {
                        setShowAlertModal(true);
                        setAlertModalContent("관리자 연락하시기 바랍니다.");
                    }
                })
                .catch((err) => console.log("useEvaluationUpdateMutation ERROR => ", err));
        } else {
            onAction(formData)
                .then((res) => {
                    setShowConfirmModal(false);
                    if (res.data.status === 200) {
                        navigate("/mypage/paying");
                    } else {
                        setShowAlertModal(true);
                        setAlertModalContent("관리자 연락하시기 바랍니다.");
                    }
                })
                .catch((err) => console.log("useEvaluationCreateMutation ERROR => ", err));
        }
    }, [onAction, list, certCategory1, deleteFileSeq, userCertRate, apiList, workerCount]);

    let userCertList = userCertRate?.content?.filter((item) => item.stageType == 1) || [];

    const handleApiRequest = async (index) => {
        const objList = [...apiList];
        
        const obj = objList[index];

        if (!obj?.nationalResearchNum) {
            setAlertModalContent("국가연구자 번호를 입력하여 주세요.");
            setShowAlertModal(true);
            return;
        }

        if (objList.some((item, idx) => item.nationalResearchNum === obj?.nationalResearchNum && idx !== index) || userCompanyMembers?.content?.some((item, idx) => item.nationalResearchNum === obj?.nationalResearchNum)) {
            setAlertModalContent(<>국가연구자 번호 중복 동록이 안됩니다. <br/> 확인 후 등록 하시기 바랍니다.</> );
            setShowAlertModal(true);
            return;
        }

        setApiLoading(true);

        let resp = "";
        try {
            const url = SERVER_URL + "/web/national/research/num?saupNum=" + obj?.nationalResearchNum;
            const requestOptions = {
                method: "get",
                headers: {}
            };

            const response = await customRequestAPI(url, requestOptions);

            resp = response?.content || "";
        } catch (err) {
            if (err.message === "Network Error") {
                console.error("Network error. Please check your connection.");
            }
        } finally {
            setApiLoading(false);
        }

        if (resp == null || resp == "null") {
            setAlertModalContent("이미 등록된 국가연구자 번호입니다.");
            setShowAlertModal(true);
            return;
        }

        let decodedString = decodeBase64(resp);

        let convertedJson = handleConvert(decodedString);
        let convertedObj = convertedJson?.인력정보?.인력기본정보;

        let apiObj = {
            memberName: !isEmptyObject(convertedObj?.성명_한글) ? convertedObj?.성명_한글 : "",
            nationalResearchNum: !isEmptyObject(convertedObj?.국가연구자번호) ? convertedObj?.국가연구자번호 : "",
            birthday: !isEmptyObject(convertedObj?.생년월일) ? convertedObj?.생년월일 : "",
            institutionName: !isEmptyObject(convertedObj?.기관명) ? convertedObj?.기관명 : "",
            position: !isEmptyObject(convertedObj?.직위) ? convertedObj?.직위 : "",
            mobileNum: !isEmptyObject(convertedObj?.휴대전화) ? convertedObj?.휴대전화 : ""
        };
        setApiLoading(false);

        updateApiListByIndex(index, { ...apiObj });
    };

    const isEmptyObject = (obj) => {
        return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    const updateApiListByIndex = (index, newObject) => {
        setApiList((prevApiList) => {
            const updatedApiList = [...prevApiList];
            updatedApiList[index] = newObject;
            return updatedApiList;
        });
    };

    const handleConvert = (xmlInput) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlInput, "text/xml");
        const json = xmlToJson(xmlDoc);
        return json;
    };

    const handleCloseNoAccessModal = () => {
        setShowAlertModal(false);
        navigate("/mypage/selfdiagnosis");
    };

    if (selfData?.data == null || selfData?.data?.isPass === "N") {
        return (
            <AlertModal
                open={showAlertModal}
                close={handleCloseNoAccessModal}
                onClick={handleCloseNoAccessModal}
                title={"안내"}
                text={"현재 자가진단 합격 상태가 아닙니다. \n 자가진단 후 1단계 평가 신청가능합니다"}
            />
        );
    }

    return (
        <div className="content_inn container evaluation_data">
            {isCertCategory1Loading && <Loader />}
            <h4 className="sub_cos_title">1단계 평가</h4>
            {
                userCertRate?.content?.filter((item) => item.stageType === 1 && item.sectionPoint == null)?.length > 0 ? (
                    point > 550 ? (
                        <div style={{ padding: "20px 20px", background: "#ffebcd" }}>
                            <span style={{ fontWeight: "bold" }}>{userInfo?.content?.coName}님 </span>
                            <span style={{ marginBottom: "10px" }}>
                        귀사의 1단계평가 점수는 <span style={{ fontWeight: "bold" }}>"{point || 0}"</span>점 입니다. 2단계 평가를 신청해 주세요
                    </span>
                            <div className="btn_wrap" style={{ justifyContent: "start", marginTop: "15px" }}>
                                <CustomButton link={"/mypage/eligibility"} text="2단계 평가 신청하기" className="step_next_button" size={"md"} />
                            </div>
                        </div>
                    ) : (
                        <div style={{ padding: "20px 20px", background: "#ffebcd" }}>
                            <span style={{ fontWeight: "bold" }}>"{userInfo?.content?.coName}" 님은 </span>
                            <span style={{ color: "#4caf50" }}>“{point || 0}”</span> 점으로 1평가 인증자격기준 미달 입니다. 재평가 하시기 바랍니다
                        </div>
                    )
                ) : (
                    <div style={{ padding: "20px 20px", background: "#ffebcd" }}>
                        <span style={{ fontWeight: "bold" }}>"{userInfo?.content?.coName}"님 </span>
                        현재 <span style={{ color: "#4caf50" }}>“1단계 평가 대기중”</span> 입니다. (신청일로부터 3일이내 평가)
                    </div>
                )
            }

            <Steps step={"step_03"} />
            {!isUserCertRateLoading && !isCertCategory1Loading && !dataYn ? (
                <EvaluationCreateTable
                    userCertRate={userCertList}
                    certCategory={certCategory1}
                    groupCode={groupCode}
                    isLoading={isCertCategory1Loading}
                    list={list}
                    setShowApiModal={setShowApiModal}
                    sendList={setList}
                    deleteFileSeq={deleteFileSeq}
                    setDeleteFileSeq={setDeleteFileSeq}
                />
            ) : (
                <EvaluationUpdateTable
                    userCertRate={userCertList}
                    certCategory={certCategory1}
                    groupCode={groupCode}
                    isLoading={isCertCategory1Loading}
                    list={list}
                    setShowApiModal={setShowApiModal}
                    sendList={setList}
                    deleteFileSeq={deleteFileSeq}
                    setDeleteFileSeq={setDeleteFileSeq}
                />
            )}

            <div className="btn_wrap">
                <CustomSubmitButton
                    onClick={() => {
                        setShowConfirmModal(true);
                    }}
                    className="step_next_button"
                    text="1단계 평가 신청하기"
                    size="lg"
                    disabled={resultAction?.isLoading || resultUpdateAction?.isLoading}
                />
            </div>

            <ConfirmModal
                open={showConfirmModal}
                close={() => setShowConfirmModal(false)}
                onClick={() => submit()}
                title="안내"
                text={"1단계 평가자료 제출 하시겠습니까?"}
                loading={resultAction?.isLoading || resultUpdateAction?.isLoading}
                disabled={resultAction?.isLoading || resultUpdateAction?.isLoading}
            />

            <AlertModal
                open={showAlertModal}
                close={() => setShowAlertModal(false)}
                onClick={() => setShowAlertModal(false)}
                title={"안내"}
                text={alertModalContent}
                loading={resultAction?.isLoading || resultUpdateAction?.isLoading}
                disabled={resultAction?.isLoading || resultUpdateAction?.isLoading}
            />

            <CustomModal
                open={showApiModal}
                onClose={() => setShowApiModal(false)}
                width={{ xs: "95%", sm: "95%", md: "85%" }}
                title="안내"
            >
                <Grid item xs={12}>
                    <span style={{ background: "yellow", color: "red" }}>
                        국가연구자번호를 모르시거나 등록이 안된 경우 (https://www.iris.go.kr/mbrs/srch/retrieveSrchNumInfo.do) 조회 또는
                        가입하실 수 있습니다.
                    </span>
                </Grid>
                <Grid container justifyContent={"center"} gap={3} mt={"30px"}>
                    <Grid item xs={12}>
                        <div style={{ marginBottom: "10px" }}>
                            귀사의 4대보험 가입자는 몇 명이며, 이중 국가연구자 번호를 보유한 사원을 입력하여 주십시오.{" "}
                            <span style={{ background: "yellow", color: "red" }}>
                                (첨부파일 업로드... 4대보험 가입자명부(최근 2주이내발급.))
                            </span>
                        </div>
                        <div style={{ marginBottom: "20px" }}>
                            당사의 4대보험 가입자는
                            <input
                                type="number"
                                className={"custom_input"}
                                defaultValue={workerCount}
                                onChange={(e) => setWorkerCount(e.target.value)}
                                style={{ width: "80px", textAlign: "right", padding: "7px 0" }}
                            />
                            명 이고, 이중 국가 연구자 번호를 보유한 과학기술인은
                            <span style={{ fontWeight: "bold" }}>
                                {apiList?.filter((obj) => obj.memberName && obj.memberName.trim() !== "").length || 0}명{" "}
                            </span>
                            입니다.
                        </div>
                        <table style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td className="center">성명</td>
                                    <td className="center">국가연구자 번호</td>
                                    <td className="center">찾기</td>
                                    <td className="center">생년월일</td>
                                    <td className="center">기관명</td>
                                    <td className="center">직위</td>
                                    <td className="center">휴대전화</td>
                                </tr>
                                {
                                    userCompanyMembers?.content && (
                                        userCompanyMembers?.content?.map((item, i) => (
                                            <tr key={"1_" + i}>
                                                <td className="center">{item.memberName || "-"}</td>
                                                <td className="">
                                                    <input
                                                        type="number"
                                                        className={"custom_input"}
                                                        defaultValue={item.nationalResearchNum || ""}
                                                        disabled={true}
                                                    />
                                                </td>
                                                <td className="center" style={{ display: "flex", justifyContent: "center" }}>
                                                    <CustomSubmitButton
                                                        className="step_next_button"
                                                        text="찾기"
                                                        size="sm"
                                                        loading={apiLoading}
                                                        disabled={true}
                                                    >
                                                        찾기
                                                    </CustomSubmitButton>
                                                </td>
                                                <td className="center">{item.birthday || "-"}</td>
                                                <td className="center">{item.institutionName || "-"}</td>
                                                <td className="center">{item.position || "-"}</td>
                                                <td className="center">{item.mobileNum || "-"}</td>
                                            </tr>
                                        ))
                                    )
                                }
                                {apiList?.map((item, i) => (
                                    <tr key={i}>
                                        <td className="center">{apiList[i].memberName || "-"}</td>
                                        <td className="">
                                            <input
                                                type="number"
                                                className={"custom_input"}
                                                defaultValue={apiList[i]?.nationalResearchNum || ""}
                                                onChange={(e) => updateApiListByIndex(i, { nationalResearchNum: e.target.value })}
                                            />
                                        </td>
                                        <td className="center" style={{ display: "flex", justifyContent: "center" }}>
                                            <CustomSubmitButton
                                                onClick={() => {
                                                    handleApiRequest(i).then(null);
                                                }}
                                                className="step_next_button"
                                                text="찾기"
                                                size="sm"
                                                loading={apiLoading}
                                                disabled={apiLoading || (apiList[i].memberName && apiList[i].nationalResearchNum)}
                                            >
                                                찾기
                                            </CustomSubmitButton>
                                        </td>
                                        <td className="center">{apiList[i].birthday || "-"}</td>
                                        <td className="center">{apiList[i].institutionName || "-"}</td>
                                        <td className="center">{apiList[i].position || "-"}</td>
                                        <td className="center">{apiList[i].mobileNum || "-"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                        <Button
                            onClick={() => {
                                setApiList([...apiList, {}]);
                            }}
                        >
                            추가하기
                        </Button>
                    </Grid>
                    <Grid item xs={12} textAlign={"center"} display={"flex"} justifyContent={"center"} gap={"10px"}>
                        <Button
                            disableElevation
                            variant="outlined"
                            color="primary"
                            disabled={resultAction?.isLoading}
                            onClick={() => {
                                setShowApiModal(false);
                                setApiList([{}, {}, {}]);
                            }}
                            sx={{ padding: "5px 30px" }}
                        >
                            취소
                        </Button>
                        <LoadingButton
                            loading={resultAction?.isLoading}
                            disabled={resultAction?.isLoading}
                            variant="contained"
                            onClick={() => {
                                if (!isUserInfoLoading && workerCount == 0) {
                                    setShowAlertModal(true);
                                    setAlertModalContent("작업자 수는 0이 될 수 없습니다.");
                                    setShowConfirmModal(false);
                                    return;
                                }
                                setShowApiModal(false);
                            }}
                            sx={{ padding: "5px 30px" }}
                        >
                            확인
                        </LoadingButton>
                    </Grid>
                </Grid>
            </CustomModal>
        </div>
    );
};
export default EvaluationDataForm;
