import React, { lazy } from "react";
import Loadable from "components/Loadable";
import { OrderApi } from "api/order/order";
const CertOrder = Loadable(lazy(() => import("./certOrder")));

const CertOrderConfig = {
    routes: [
        {
            path: "",
            children: [
                {
                    path: "certificate",
                    element: <CertOrder />
                }
            ]
        }
    ],
    API: OrderApi
};

export default CertOrderConfig;
