import React from "react";
import { styled } from "@mui/system";
import Button from "./Button";
import { CircularProgress } from "@mui/material";

// Style
import { ButtonStyle } from "./style";

const BaseButton = styled(Button)({});

const CloseButton = ({ size, text, loading, type, ...props }) => {
    return (
        <>
            <BaseButton
                type={type || "button"}
                variant="outlined"
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} color="light" /> : ""}
                {...props}
                size={size}
                sx={ButtonStyle.closeBtn}
            >
                {text}
            </BaseButton>
        </>
    );
};
export default CloseButton;
