export const inputStyle = {
    fileInputSkeleton: {
        borderRadius: '12px',
    },
    fileInputOutlinedNone: {
        display: 'none',
    },
    fileInputOutlinedInput: {
        marginRight: '20px', 
        paddingRight: '0'
    },
    fileInputOutlinedButton: {
        height: '50px', borderRadius: '12px', width: '150px'
    },
    fileInputButtonFileInput: {
        width: '70%',
        height: '200px',
        border: 1,
        borderRadius: '16px',
        marginTop: "10px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
    },
    // Image Uploader
    imgUploadGrid: {
        height: "100%"
    },
    imgUploadBox: {
        width: "100%",
        height: "100%",
        display:"flex", 
        justifyContent: "left",
    },
    imgUploadAvatar: {
        background: "none", 
        width: "20px",  
        height:"20px", 
        margin:"0 auto 8px",
    },
    imgUploadTypography: {
        width: "100px", 
        overflow: "hidden",
        fontSize: "12px",
        textAlign: "left", 
        marginTop: "8px", 
        color:"#3D78CF", 
        cursor: "pointer",
    },
    imgUploadP: {
        cursor : 'pointer',
        fontSize : '11px',
        margin: "5px 0", 
        color: "#000",
        width: "63px",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    formInput: {
        '.MuiInputBase-root': {
            borderRadius: "5px !important",
        }
    },
    //Lang Input
    langInput: {
        marginBottom: "10px", 
        padding: "4px 20px",
        background: "#fff",
        '.MuiInputBase-input':  {
            background: "#fff",
            color: '#363636',
          }       
    },
    //Search Input
    searchInput: {
        padding: "5px 20px",
        background: "#fff",
        '.MuiInputBase-input':  {
            background: "#fff",
            color: '#363636',
            },
        '&:hover': {
            borderColor: "none"
        }       
    },
    searchInputBtn: {
        minWidth: "0",
    },
    // TextArea
    textAreaBase: {
        "&.MuiFormControl-root": {
            width: "100% !important",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "0px !important",
            padding: "12px !important",
            width: "100% !important",
           
          },
          "& .MuiOutlinedInput-input": {
            border: "none",
            padding: "0 !important",
            width: "100% !important",
            "&.Mui-disabled": {
              backgroundColor: "#EFEFEF !important",
            },
          },
    }

}