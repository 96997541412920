import React from "react";
import { TextField } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";

export const FileUploadButton = ({ size, onChange, disabled, inputProps, error, helperText }) => {
    const handleClick = () => {
        document.getElementById(inputProps?.id || "uploadImageInput").click();
    };
    return (
        <div className="upload-btn-wrapper">
            <button
                className="file-upload-btn"
                variant="outlined"
                component="label"
                name={inputProps?.name}
                size={size}
                type="button"
                onClick={handleClick}
            >
                <UploadIcon />
                파일 추가
                <TextField
                    id={inputProps?.id || "uploadImageInput"}
                    type="file"
                    onChange={onChange}
                    sx={{ display: "none" }}
                    disabled={disabled}
                    error={error}
                    helperText={helperText}
                    inputProps={inputProps}
                />
            </button>
        </div>
    );
};
