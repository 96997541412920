import React, { forwardRef } from "react";
import { Box } from "@mui/system";

export const ModalInner = forwardRef(({ children, width }, ref) => {
    return (
        <Box
            ref={ref}
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "#FFFFFF",
                padding: "30px 25px",
                borderRadius: "0",
                width: width || 400,
                "@media (max-width: 600px)": {
                    padding: "30px 10px"
                },
                "@media (max-width: 700px)": {
                    width: "90%",
                    padding: "20px 10px"
                }
            }}
        >
            {children}
        </Box>
    );
});
