import { createApi } from "@reduxjs/toolkit/query/react";
import { Api } from "../index";
import { URL } from "store/urls";

export const AuthApi = createApi({
    reducerPath: "AuthApi",
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ["Auth"],
    endpoints: (build) => ({
        login: build.mutation({
            query: (data) => ({
                url: URL.LOGIN,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["INFO"]
        }),
        snsLogin: build.mutation({
            query: (data) => ({
                url: URL.SNS_LOGIN,
                method: "POST",
                body: data
            }),
            invalidatesTags: ["INFO"]
        }),

        // USER API's
        getUserInfo: build.query({
            query: () => `${URL.USER_INFO}`,
            providesTags: ["INFO"]
        }),
        createUser: build.mutation({
            query: (body) => ({
                url: `${URL.USER_CREATE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["INFO"]
        }),
        updateUser: build.mutation({
            query: (body) => ({
                url: `${URL.USER_UPDATE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["INFO"]
        }),
        updateUserImages: build.mutation({
            query: (body) => ({
                url: `${URL.USER_UPDATE_IMAGES}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["LIST"]
        }),

        // Check
        userNameCheck: build.mutation({
            query: (body) => ({
                url: `${URL.USER_NAME_CHECK}/` + body.saupNum,
                method: "POST"
            })
        }),
        ceoEmailCheck: build.mutation({
            query: (body) => ({
                url: `${URL.CEO_EMAIL_CHECK}/` + body.ceoEmail,
                method: "POST"
            })
        }),
        writerEmailCheck: build.mutation({
            query: (body) => ({
                url: `${URL.CO_EMAIL_CHECK}/` + body.writerEmail,
                method: "POST"
            })
        }),

        // OTHER
        userVerifyTokenSend: build.mutation({
            query: (body) => ({
                url: `${URL.EMAIL_VERIFICATION_SEND}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["INFO"]
        }),
        verifyToken: build.mutation({
            query: (body) => ({
                url: `${URL.EMAIL_VERIFY}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["INFO"]
        }),
        forgotId: build.mutation({
            query: (body) => ({
                url: `${URL.FORGOT_ID}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["INFO"]
        }),
        forgotPassword: build.mutation({
            query: (body) => ({
                url: `${URL.FORGOT_PASSWORD}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["INFO"]
        })
    })
});

export const {
    useLoginMutation,
    useSnsLoginMutation,

    useCreateUserMutation,
    useGetUserInfoQuery,
    useUpdateUserMutation,
    useUpdateUserImagesMutation,

    useUserNameCheckMutation,
    useCeoEmailCheckMutation,
    useWriterEmailCheckMutation,

    useUserVerifyTokenSendMutation,
    useVerifyTokenMutation,
    useForgotIdMutation,
    useForgotPasswordMutation
} = AuthApi;
