import React, { lazy } from "react";
import Loadable from "components/Loadable";
import { ApplicationApi } from "api/application/index";
// import Financial from "./financial";
const Application = Loadable(lazy(() => import("./application")));
const Financial = Loadable(lazy(() => import("./financial")));

const ApplicationConfig = {
    routes: [
        {
            path: "",
            children: [
                {
                    path: "application",
                    element: <Application />
                },
                {
                    path: "financial",
                    element: <Financial />
                }
            ]
        }
    ],
    API: ApplicationApi
};

export default ApplicationConfig;
