import React from "react";
import { Controller } from "react-hook-form";
import { TextInputField } from "./default";

const TextInput = ({ error, ...props }) => {
    return (
        <Controller
            name={props?.name}
            control={props?.control}
            render={({ field }) => (
                <TextInputField value={field?.value} placeholder={props?.placeholder} error={error} {...field} {...props} />
            )}
        />
    );
};

export default TextInput;
