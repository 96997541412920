import React, {forwardRef, useState} from 'react';
import {OutlinedInput, Skeleton, Box, InputAdornment, IconButton} from '@mui/material';
import {Visibility, VisibilityOff} from "@mui/icons-material";

const CustomTextInputField = forwardRef(({ sx, isLoading, placeholder, onChange, onBlur, error, textAlign, ...props }, ref) => {

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    return isLoading ? (
        <Skeleton animation="wave" width={'200px'} height={'25px'} sx={{ borderRadius: '4px' }} />
    ) : (
        <Box sx={{ width: "100%", borderRadius: '12px', display: 'flex', alignItems: 'center' }}>
            <OutlinedInput
                ref={ref}
                {...props}
                type={props?.type == 'password' ? showPassword ? 'text' : 'password' : 'text'}
                error={props?.erros}
                onChange={onChange}
                value={props?.value || props?.value}
                size="small"
                placeholder={placeholder || ''}
                onBlur={onBlur}
                sx={{ border: '1px solid #ddd',
                    lineHeight: '24px',
                    outline: 'none',
                    width: sx?.width ? sx?.width : "100%",
                    borderColor: error?.message ? "#f53334" : "#d7d7d7",
                    borderRadius: props?.radius ? props?.radius : '12px',
                    marginTop: props?.margintop ? props?.margintop : '15px',
                    '& ::placeholder': {color : error?.message ? "#f53334" : "#a6a6a6"},
                    ...sx
                }}
                inputProps={{
                    maxLength: props?.maxLength || 150,
                    style: { textAlign: textAlign || 'start' }
                }}
                endAdornment={props?.type === 'password' ? (
                    <InputAdornment position="end" sx={{right: '10px'}}>
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={(event) => event.preventDefault()}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ) : ''}
            />
            {props?.unit && (<span style={{marginTop: '15px'}}>{props?.unit}</span>)}
        </Box>
    );
});

export default CustomTextInputField;
