import React, { useCallback, useEffect, useState } from "react";
import Steps from "../step/steps";
import { CustomSubmitButton } from "components/buttons/CustomButton";
import { TextInput } from "components/useForm";
import { useForm } from "react-hook-form";
import { useGetPaymentDetailQuery } from "api/payment/payment";
import { isValidEmail } from "utils/functions/common";
import { ConfirmModal } from "components/index";
import AlertModal from "components/modal/AlertModal";
import { useGetUserInfoQuery, useUpdateInvoiceEmailMutation } from "api/application";
import { useGetSiteInfoQuery } from "api/main/main";
import Loader from "components/Loader";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const Paying = () => {
    const navigate = useNavigate();
    const saupNum = localStorage.getItem("saupNum");
    const user = useSelector((state) => state.authentication.user);

    const { data: mydata, isLoading: mydataIsLoading } = useGetUserInfoQuery();
    const { data: siteInfo, loading } = useGetSiteInfoQuery();

    const { data, idLoading } = useGetPaymentDetailQuery();
    const [onAction, resultAction] = useUpdateInvoiceEmailMutation();

    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalContent, setAlertModalContent] = useState("");
    const [checkRequiredItems, setCheckRequiredItems] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors }
    } = useForm({
        mode: "onBlur"
    });

    useEffect(() => {
        if (mydata?.status === 200 && mydata?.content) {
            setValue("invoiceEmail", mydata?.content?.invoiceEmail || "");
        }
    }, []);

    const certPay = parseInt(String(data?.content?.certPay)) || 0;
    const placePay = parseInt(String(data?.content?.placePay)) || 0;

    const payTotal = certPay + placePay;
    const payTotalFormatted = payTotal.toLocaleString();

    const submit = useCallback(
        (values) => {
            const newData = {
                ...values,
                saupNum: saupNum
            };

            let formData = new FormData();
            Object.keys(newData).forEach((key) => {
                formData.append(key, newData[key]);
            });
            formData.append("paymentStatus", "PAY_01");

            onAction(formData)
                .then((res) => {
                    setShowConfirmModal(false);
                    if (res.data.status === 200) {
                        if (!user) {
                            localStorage.removeItem("app_url");
                            localStorage.removeItem("saupNum");
                            navigate("/login");
                        } else {
                            navigate("/mypage/eligibility");
                        }
                    } else {
                        setShowAlertModal(true);
                        setAlertModalContent("관리자 연락하시기 바랍니다.");
                    }
                })
                .catch((err) => console.log("useUserUpdateMutation ERROR => ", err));
        },
        [onAction, watch]
    );

    const handleSubmitButton = handleSubmit((values) => {
        if (values.invoiceEmail === undefined || values.invoiceEmail.trim() === "") {
            setShowAlertModal(true);
            setAlertModalContent("이메일을 입력하여 주세요.");
            return;
        }

        if (!isValidEmail(values.invoiceEmail)) {
            setShowAlertModal(true);
            setAlertModalContent("유효한 이메일 형식을 입력하여 주세요.");
            return;
        } else {
            setCheckRequiredItems(true);
            setShowConfirmModal(true);
        }
    });

    return (
        <div className="content_inn container paying">
            {idLoading && <Loader />}
            <h4 className="sub_cos_title">평가 신청 결제하기</h4>
            <Steps step={"step_03"} />

            <form onSubmit={handleSubmit(submit)}>
                <div className="paying_inn">
                    {/* <h5>최초인증</h5> */}
                    <table>
                        <caption>최초인증</caption>
                        <tbody>
                            <tr>
                                <td className="hdr">인증평가 수수료</td>
                                <td className="bdy bdy_flex">{certPay.toLocaleString()}원</td>
                            </tr>
                            <tr>
                                <td className="hdr">현장평가 수수료</td>
                                <td className="bdy bdy_flex">{placePay.toLocaleString()}원</td>
                            </tr>
                            <tr>
                                <td className="hdr" style={{ background: "#F27D5B", color: "#fff" }}>
                                    합 계(VAT별도)
                                </td>
                                <td className="bdy bdy_flex">{payTotalFormatted} 원</td>
                            </tr>
                            <tr>
                                <td className="hdr">세금계산서 이메일</td>
                                <td className="bdy">
                                    <TextInput
                                        control={control}
                                        name="invoiceEmail"
                                        maxLength="30"
                                        placeholder="세금계산서 발행 요청 이메일 입력하여 주세요."
                                        width="400px"
                                        type="email"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="hdr">무통장입금계좌</td>
                                <td className="bdy">
                                    <ul>
                                        <li>은행 : {siteInfo?.content?.bank}</li>
                                        <li>계좌번호 : {siteInfo?.content?.accountNumber}</li>
                                        <li>예금주: {siteInfo?.content?.accountHolder}</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="btn_wrap">
                        <CustomSubmitButton
                            type="button"
                            onClick={handleSubmitButton}
                            className="step_next_button"
                            text={"확인 요청 보내기"}
                            size="lg"
                        />
                    </div>
                </div>
            </form>
            {checkRequiredItems && (
                <ConfirmModal
                    open={showConfirmModal}
                    close={() => setShowConfirmModal(false)}
                    onClick={handleSubmit(submit)}
                    title="안내"
                    text={"결제 확인 요청을 보내시겠습니까?"}
                    desc={`관리자가 승인 시 로그인 후 마이페이지에서 \n 2단계 적격성 평가 신청 가능합니다.`}
                    loading={resultAction?.isLoading}
                    disabled={resultAction?.isLoading}
                />
            )}

            <AlertModal
                open={showAlertModal}
                close={() => setShowAlertModal(false)}
                onClick={() => setShowAlertModal(false)}
                title={"안내"}
                text={alertModalContent}
                loading={resultAction?.isLoading}
                disabled={resultAction?.isLoading}
            />
        </div>
    );
};
export default Paying;
