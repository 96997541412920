import React from "react";
import { useTranslation } from "react-i18next";
import UploadIcon from "@mui/icons-material/Upload";

const style = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #F27D5B",
    padding: "5px 0",
    justifyContent: "center",
    gap: "5px",
    cursor: "pointer",
    color: "#F27D5B",
    borderRadius: "4px",
    position: "relative",
    zIndex: "1"
};

const UploadButton = ({ text }) => {
    const { t } = useTranslation();

    return (
        <div style={style}>
            <UploadIcon />
            <span>{text || "업로드"}</span>
        </div>
    );
};

export default UploadButton;
