import React from "react";

const defaultValue = {
    locale: "kr",
    setLocale: () => {},
    saupNum: "",
    setSaupNum: () => {},
    isTerms: false,
    setIsTerms: () => {}
};

export default React.createContext(defaultValue);
