export const URL = {
    LOGIN: "/web/login",
    MENU_LIST: "/web/menu/list",
    SITE_INFO: "/web/site/info",
    SITE_SETTINGS_INFO: "/web/site/settings/info",
    USER_INFO: "/web/user/info",
    USER_UPDATE: "/web/user/update",
    USER_CREATE: "/web/user/create",
    USER_NAME_CHECK: "/web/user/userIdExist",
    CEO_EMAIL_CHECK: "/web/user/ceoEmailExist",
    CO_EMAIL_CHECK: "/web/user/writerEmailExist",
    EMAIL_VERIFICATION_SEND: "/web/email/verification/send",
    EMAIL_VERIFY: "/web/email/auth/verify",
    FORGOT_ID: "/web/forgot/id",
    FORGOT_PASSWORD: "/web/forgot/password",
    PAYMENT_DETAIL: "/web/payment",
    INVOICE_EMAIL: "/web/user/invoiceEmail",

    USER_ADDRESS_LIST: "/web/user/address/list",
    USER_ADDRESS_CREATE: "/web/user/address/create",
    USER_ADDRESS_UPDATE: "/web/user/address/update",
    USER_ADDRESS_DELETE: "/web/user/address/delete",

    FINANCIAL_LIST: "/web/financial/list",
    FINANCIAL_CREATE: "/web/financial/create",
    FINANCIAL_REGISTER: "/web/financial/register",
    FINANCIAL_UPDATE: "/web/financial/update",

    SELF_DIAGNOSIS: "/web/question/list",
    SELF_DIAGNOSIS_CREATE: "/web/question/create",
    SELF_DIAGNOSIS_DETAIL: "/web/question/detail",
    SELF_DIAGNOSIS_UPDATE: "/web/question/update",

    USER_CERT_RATE_DETAIL: "/web/user/cert/rate/detail",
    USER_CERT_RATE_DETAIL_DEFAULT_REGISTERED: "/web/user/cert/rate/default/registered",
    USER_CERT_RATE_CREATE_BULK: "/web/user/cert/rate/create/bulk",
    USER_CERT_RATE_REGISTER_BULK: "/web/user/cert/rate/register/bulk",
    USER_CERT_RATE_UPDATE_BULK: "/web/user/cert/rate/update/bulk",

    USER_COMPANY_MEMBER_LIST: "/web/user/company/member/list",

    ORDER: "/web/order/list",
    ORDER_CREATE: "/web/cert/order/create",

    PILL_TYPE: "/web/pill/type/detail",
    CONCEPT_LIST: "/web/project/concepts",
    PACKAGE_LIST: "/web/project/packages",
    FORMULATION_LIST: "/web/project/formulations",

    COMMONCODE_FILTER_LIST: "/web/codes/group",
    COMMONCODE_FILTER_BY_GROUP_LIST: "/web/codes/group/list",

    // NOTICE
    NOTICE_LIST: "/web/notice/list",
    NOTICE_DETAIL: "/web/notice/detail",

    NEWS_LIST: "/web/news/list",
    NEWS_DETAIL: "/web/news/detail",

    EVENT_LIST: "/web/event/list",
    EVENT_DETAIL: "/web/event/detail",

    QNA_LIST: "/web/qna/list",
    QNA_DETAIL: "/web/qna/detail",
    QNA_CREATE: "/web/qna/create",
    QNA_UPDATE: "/web/qna/update",
    QNA_DELETE: "/web/qna/delete",

    CONTACT: "/web/contact/create",
    CONTACT_LIST: "/web/contact/list",
    CONTACT_DETAIL: "/web/contact/detail",

    INQUIRY_CREATE: "/web/inquiry/create",

    GALLERY_LIST: "/web/gallery/list",
    GALLERY_DETAIL: "/web/gallery/detail",

    FAQ_LIST: "/web/faq/list",
    DYNAMIC_PAGE: "/web/menu/contents",

    REVIEW_LIST: "/web/review/list",
    REVIEW_DETAIL: "/web/review/detail",
    REVIEW_CREATE: "/web/review/create",

    STAFF_LIST: "/web/staff/list",
    STAFF_CEO: "/web/staff/detail/ceo",
    STAFF_CTO: "/web/staff/detail/cto",
    STAFF_VICE: "/web/staff/detail/president",
    STAFF_VICE_TWO: "/web/staff/detail/presidenttwo",
    STAFF_DETAIL: "/web/staff/detail",
    STAFF_CREATE: "/web/staff/create",

    CERT_LIST: "/web/certificate/list",

    PORTFOLIO_LIST: "/web/portfolio/list",
    PORTFOLIO_DETAIL: "/web/portfolio/detail",
    YOUTUBE_LIST: "/web/youtube/list",

    APPLICATION: "/web/application",

    //MAIN
    MAIN_INFO: "/web/main",
    CURRENT_TIME: "/web/current/time",

    CERT_CATEGORY_TYPE_LIST: "/web/cert/category/type/list"

    //BANNER
    // BANNER_LIST: '/web/main'
};
