import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVER_URL } from "config";

/**
 * fetchBaseQuery which contain access token for createAPI function in all page components
 */
export const Api = {
    fetchBaseQuery: fetchBaseQuery({
        baseUrl: SERVER_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().authentication.user;

            if (token) {
                if (parseJwt(token)?.exp * 1000 > Date.now()) {
                    headers.set("authorization", `Bearer ${token}`);
                }
            }
        }
    })
};

const parseJwt = (token) => {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
};
