import React, { useCallback, useContext, useEffect, useState } from "react";
import Steps from "../step/steps";
import { Controller, useForm } from "react-hook-form";
import stepCaution from "assets/imgs/icon/ico_caution.png";
import { RadioGroup, TextInput, Select, DatePicker, BusinessRegistrationInput } from "components/useForm";
import { useGetCommonByGroupCodesQuery } from "api/commonCode";
import { CustomSubmitButton } from "components/buttons/CustomButton";
import DaumPostcode from "react-daum-postcode";
import { BasicModal } from "components/modal/basicModal";
import { Button, Grid, Typography } from "@mui/material";
import {
    useCeoEmailCheckMutation,
    useWriterEmailCheckMutation,
    useCreateUserMutation,
    useUserNameCheckMutation,
    useGetUserInfoQuery,
    useUpdateUserMutation
} from "api/auth/auth";
import { useNavigate } from "react-router";
import { ValidationApplication } from "utils/customValidation";
import { ConfirmModal } from "components/index";
import AlertModal from "components/modal/AlertModal";
import LocaleContext from "LocaleContext";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import { isValidEmail } from "utils/functions/common";
import { CustomModal } from "components";
import { LoadingButton } from "@mui/lab";

import IndustryTable from "./industryTable";

const formDefaultValues = {
    seq: 0,
    saupNum: "",
    memberTypeCd: "MT_01",
    coName: "",
    coType: "MT_01",
    lawNum01: "",
    lawNum02: "",
    ceoName: "",
    ceoBirth: "",
    ceoGender: "M",
    ceoEmail: "",
    ceoPhone: "",
    writerName: "",
    writerEmail: "",
    emailYn: "Y",
    faxYn: "Y",
    password: "",
    coDate: null,
    coPhone: "",
    coFax: "",
    coZip: "",
    coAddr1: "",
    coAddr2: "",
    coUrl: "",
    coSanjang: "N",
    coBaseMoney: 0,
    coCategory: "",
    coCategoryCode: "",
    coCategoryDescr: "",
    accountMonth: "",
    financialFix: "Y",
    financialKeep: "Y",
    financialGuaranty: "Y",
    membershipYn: "Y",
    engineeringYn: "Y",
    coStatus: "",
    referralCode: "",
    attachFile: null
};

const Application = () => {
    const navigate = useNavigate();
    const { isTerms } = useContext(LocaleContext);
    const user = useSelector((state) => state.authentication.user);

    const [addressModal, setAddressModal] = useState(false);
    const [onAction, resultAction] = useCreateUserMutation();
    const [onActionUpdate, resultActionUpdate] = useUpdateUserMutation();
    const [userNameCheckAction, { isLoading: isNameChecking, data: nameCheckResult }] = useUserNameCheckMutation();
    const [ceoEmailCheckAction, { isLoading: isCeoEmailChecking, data: ceoEmailCheckResult }] = useCeoEmailCheckMutation();
    const [writerEmailCheckAction, { isLoading: isWriterEmailChecking, data: writerEmailCheckResult }] = useWriterEmailCheckMutation();
    const [checkRequiredItems, setCheckRequiredItems] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalContent, setAlertModalContent] = useState("");
    const [showApiModal, setShowApiModal] = useState(false);
    const [industryType1, setIndustryType1] = useState(null);
    const [industryType2, setIndustryType2] = useState(null);
    const [industryType3, setIndustryType3] = useState(null);
    const { data: mydata, isLoading: mydataIsLoading, refetch } = useGetUserInfoQuery();

    const [ceoEmailChanged, setCeoEmailChanged] = useState(false);
    const [writerEmailChanged, setWriterEmailChanged] = useState(false);
    const { data: groupCode, isLoading: groupIsLoading } = useGetCommonByGroupCodesQuery([
        "MEMBER_TYPE",
        "USR_GNDR",
        "PHONE_TYPE",
        "USE_YN",
        "AGREE_OR_NOT",
        "SANJANG",
        "RELATED_INDUSTRIES",
        "MONTH",
        "YES_OR_NO"
    ]);

    const {
        control,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors }
    } = useForm({
        mode: "onBlur",
        defaultValues: formDefaultValues
    });

    const handleSaupNumDuplicate = useCallback(async () => {
        const saupNum = watch("saupNum");

        if (!saupNum) {
            setShowAlertModal(true);
            setAlertModalContent("사업자번호를 입력하여 주세요.");
            return false;
        }

        try {
            const response = await userNameCheckAction({ saupNum });
            if (response.data.status === 200) {
                setShowAlertModal(true);
                setAlertModalContent("사용하실 수 있는 사업자번호입니다");
                return true;
            } else {
                setShowAlertModal(true);
                setAlertModalContent("이미 사용 중인 사업자등록번호입니다.");
                return false;
            }
        } catch (error) {
            console.error("API 호출 오류:", error);
            setShowAlertModal(true);
            setAlertModalContent("사업자등록번호가 잘못되었습니다");
            return false;
        }
    }, [watch, userNameCheckAction, setShowAlertModal, setAlertModalContent]);

    const handleCeoEmailDuplicate = useCallback(async () => {
        const ceoEmail = watch("ceoEmail");

        if (!ceoEmail) {
            setShowAlertModal(true);
            setAlertModalContent("이메일을 입력하여 주세요.");
            return false;
        }

        if (!isValidEmail(ceoEmail)) {
            setShowAlertModal(true);
            setAlertModalContent("유효한 이메일 형식을 입력하여 주세요.");
            return false;
        }

        try {
            const response = await ceoEmailCheckAction({ ceoEmail });
            if (response.data.status === 200) {
                setShowAlertModal(true);
                setAlertModalContent("사용하실 수 있는 이메일입니다");
                return true;
            } else {
                setShowAlertModal(true);
                setAlertModalContent("이미 사용 중인 이메일입니다.");
                return false;
            }
        } catch (error) {
            console.error("API 호출 오류:", error);
            setShowAlertModal(true);
            setAlertModalContent("이메일이 잘못되었습니다");
            return false;
        }
    });

    const handleWriterEmailDuplicate = useCallback(async () => {
        const writerEmail = watch("writerEmail");
        if (!writerEmail) {
            setShowAlertModal(true);
            setAlertModalContent("이메일을 입력하여 주세요.");
            return false;
        }

        if (!isValidEmail(writerEmail)) {
            setShowAlertModal(true);
            setAlertModalContent("유효한 이메일 형식을 입력하여 주세요.");
            return false;
        }

        try {
            const response = await writerEmailCheckAction({ writerEmail });
            if (response.data.status === 200) {
                setShowAlertModal(true);
                setAlertModalContent("사용하실 수 있는 이메일입니다.");
                return true;
            } else {
                setShowAlertModal(true);
                setAlertModalContent("이미 사용 중인 이메일입니다.");
                return false;
            }
        } catch (error) {
            console.error("API 호출 오류:", error);
            setShowAlertModal(true);
            setAlertModalContent("이메일이 잘못되었습니다");
            return false;
        }
    });

    const validatePassword = (value) => {
        if (value === undefined) {
            return;
        }
        if (!value) {
            return "비밀번호를 입력하여 주세요.";
        }
        if (value.length < 10 || value.length > 15) {
            return "비밀번호는 10자 이상 15자 이내로 입력하여 주세요.";
        }
        const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/;
        if (!regex.test(value)) {
            return "영대문자, 영소문자, 숫자 또는 특수문자 중 2종류 이상 조합으로 입력하여 주세요.";
        }
        return "";
    };

    useEffect(() => {
        refetch();

        if (mydata?.status === 200) {
            setValue("saupNum", mydata?.content?.saupNum || "");
            setValue("coName", mydata?.content?.coName || "");
            setValue("coType", mydata?.content?.coType || "");
            setValue("lawNum01", mydata?.content?.lawNum01 || "");
            setValue("lawNum02", mydata?.content?.lawNum02 || "");
            setValue("ceoName", mydata?.content?.ceoName || "");
            setValue("ceoBirth", mydata?.content?.ceoBirth || "");
            setValue("ceoGender", mydata?.content?.ceoGender || "");
            setValue("ceoEmail", mydata?.content?.ceoEmail || "");
            setValue("writerName", mydata?.content?.writerName || "");
            setValue("writerEmail", mydata?.content?.writerEmail || "");
            setValue("emailYn", mydata?.content?.emailYn || "");
            setValue("faxYn", mydata?.content?.faxYn || "");
            setValue("coDate", mydata?.content?.coDate || "");
            setValue("coZip", mydata?.content?.coZip || "");
            setValue("coAddr1", mydata?.content?.coAddr1 || "");
            setValue("coAddr2", mydata?.content?.coAddr2 || "");
            setValue("coUrl", mydata?.content?.coUrl || "");
            setValue("coSanjang", mydata?.content?.coSanjang || "");
            setValue("coBaseMoney", mydata?.content?.coBaseMoney || "");
            setValue("coCategory", mydata?.content?.coCategory || "");
            setValue("coCategoryCode", mydata?.content?.coCategoryCode || "");
            setValue("coCategoryDescr", mydata?.content?.coCategoryDescr || "");
            setValue("referralCode", mydata?.content?.referralCode || "");
            setValue("accountMonth", mydata?.content?.accountMonth || "");
            setValue("financialFix", mydata?.content?.financialFix || "");
            setValue("financialKeep", mydata?.content?.financialKeep || "");
            setValue("financialGuaranty", mydata?.content?.financialGuaranty || "");
            setValue("membershipYn", mydata?.content?.membershipYn || "");
            setValue("engineeringYn", mydata?.content?.engineeringYn || "");

            const [phoneStart, phoneMiddle, phoneEnd] = mydata.content.coPhone.split("-");
            setValue("phoneStart", phoneStart);
            setValue("phoneMiddle", phoneMiddle);
            setValue("phoneEnd", phoneEnd);

            const [faxStart, faxMiddle, faxEnd] = mydata.content.coFax.split("-");
            setValue("faxStart", faxStart);
            setValue("faxMiddle", faxMiddle);
            setValue("faxEnd", faxEnd);

            const [ceoPhoneStart, ceoPhoneMiddle, ceoPhoneEnd] = mydata.content.ceoPhone.split("-");
            setValue("ceoPhoneStart", ceoPhoneStart);
            setValue("ceoPhoneMiddle", ceoPhoneMiddle);
            setValue("ceoPhoneEnd", ceoPhoneEnd);
        } else {
            reset();
        }
    }, [mydata, navigate]);

    const submit = useCallback(
        (values) => {
            const newData = {
                ...values,
                coBaseMoney: parseInt(String(values.coBaseMoney).replace(/,/g, ""), 10),
                password: values.password01,
                ceoPhone: values.ceoPhoneStart + "-" + values.ceoPhoneMiddle + "-" + values.ceoPhoneEnd,
                coPhone: values.phoneStart + "-" + values.phoneMiddle + "-" + values.phoneEnd,
                coFax: values.faxStart + "-" + values.faxMiddle + "-" + values.faxEnd
            };

            let formData = new FormData();
            Object.keys(newData).forEach((key) => {
                if (newData[key] != null && newData[key] != undefined && newData[key] != "" && newData[key] != "null") {
                    formData.append(key, newData[key]);
                }
            });

            if (user) {
                onActionUpdate(formData)
                    .then((res) => {
                        setShowConfirmModal(false);
                        if (res.data.status === 200) {
                            setShowAlertModal(true);
                            setAlertModalContent("기본정보 수정되었습니다.");
                        } else {
                            setShowConfirmModal(false);
                            setShowAlertModal(true);
                            setAlertModalContent("관리자 연락하시기 바랍니다.");
                        }
                    })
                    .catch((err) => console.log("useUserCreateMutation ERROR => ", err));
            } else {
                onAction(formData)
                    .then((res) => {
                        if (res.data.status === 200) {
                            localStorage.setItem("saupNum", watch("saupNum"));
                            localStorage.setItem("app_url", "/application");
                            navigate("/financial");
                        } else {
                            setShowConfirmModal(false);
                            setShowAlertModal(true);
                            if (res.data.message === "failed.duplicate.username") {
                                setAlertModalContent("이미 사용 중인 사업자등록번호입니다.");
                                return;
                            }
                            if (res.data.message === "failed.duplicate.ceoEmail") {
                                setAlertModalContent("이미 사용 중인 대표자 이메일입니다.");
                                return;
                            }
                            if (res.data.message === "failed.duplicate.writerEmail") {
                                setAlertModalContent("이미 사용 중인 담당자 이메일입니다");
                                return;
                            }
                            setAlertModalContent("관리자 연락하시기 바랍니다.");
                        }
                    })
                    .catch((err) => console.log("useUserCreateMutation ERROR => ", err));
            }
        },
        [onAction, watch, checkRequiredItems, navigate]
    );

    const handleSubmitButton = handleSubmit((values) => {
        const passwordValidation = validatePassword(values.password01);
        const passwordValidation02 = validatePassword(values.password02);
        var validText = ValidationApplication(
            values,
            nameCheckResult,
            ceoEmailCheckResult,
            writerEmailCheckResult,
            user,
            ceoEmailChanged,
            writerEmailChanged
        );

        if (validText) {
            setShowAlertModal(true);
            setAlertModalContent(validText);
            return;
        }

        if (user) {
            if (passwordValidation) {
                setShowAlertModal(true);
                setAlertModalContent(passwordValidation);
                return;
            }
            if (watch("password01") != undefined && watch("password01") !== "") {
                if (passwordValidation !== "" || passwordValidation02 != "") {
                    setShowAlertModal(true);
                    setAlertModalContent("비밀번호가 일치하지 않습니다.");
                    return;
                }
                if (watch("password01") !== watch("password02")) {
                    setShowAlertModal(true);
                    setAlertModalContent("비밀번호가 일치하지 않습니다");
                    return;
                }
            }
        } else {
            if (passwordValidation) {
                setShowAlertModal(true);
                setAlertModalContent(passwordValidation);
                return;
            }
            if (passwordValidation === undefined) {
                setShowAlertModal(true);
                setAlertModalContent("비밀번호를 입력하여 주세요.");
                return;
            }
            if (passwordValidation !== "" || passwordValidation02 != "") {
                setShowAlertModal(true);
                setAlertModalContent("비밀번호가 일치하지 않습니다.");
                return;
            }
            if (watch("password01") !== watch("password02")) {
                setShowAlertModal(true);
                setAlertModalContent("비밀번호가 일치하지 않습니다");
                return;
            }
        }

        setCheckRequiredItems(true);
        setShowConfirmModal(true);
    });

    const handleAddressComplete = (data) => {
        const fullAddress = data.address;
        const zonecode = data.zonecode;
        setValue("coZip", zonecode);
        setValue("coAddr1", fullAddress);
        setAddressModal(false);
    };
    const handleChangeEmail = (e) => {
        setValue("ceoEmail", e.target.value);
        setCeoEmailChanged(true);
    };
    const handleChangeWriterEmail = (e) => {
        setValue("writerEmail", e.target.value);
        setWriterEmailChanged(true);
    };

    const handleChangeIndustryType3 = (code, desc) => {
        setValue("coCategoryCode", code);
        setValue("coCategoryDescr", desc);
    };

    useEffect(() => {
        setIndustryType1(null);
        setIndustryType2(null);
        setIndustryType3(null);
    }, []);

    return (
        <div className="content_inn container application">
            {groupIsLoading && <Loader />}
            <h4 className="sub_cos_title">{!user ? "신규신청" : "기본정보"}</h4>
            <Steps step={"step_01"} />

            {/* step caution */}
            <div className="step_caution">
                <div className="img">
                    <img src={stepCaution} alt="caution" />
                </div>
                <ul className="txt">
                    <li>
                        1. <span>"사업자등록번호"</span> 와 <span>"비밀번호"</span>는 로그인 시 꼭 필요한 사항이므로 기억하여 주십시오.
                    </li>
                    <li>
                        2. <span>"당기"</span>는 올해 기준으로 전년도를 의미합니다.
                    </li>
                    <li>
                        3. <span>업체명, 대표자성명, 사업자등록번호, 사업자종류, 법인번호, 주소</span> 추후 변경 시 확인서 재발급을 통해서만
                        변경이 가능합니다.
                    </li>
                </ul>
            </div>

            {/* application form */}
            <form onSubmit={handleSubmit(submit)}>
                <table>
                    <caption>기업등록</caption>
                    <tbody>
                        <tr>
                            <td className="hdr">
                                업체명 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <TextInput
                                        control={control}
                                        name="coName"
                                        maxLength="30"
                                        placeholder="업체명을 입력하세요."
                                        width="400px"
                                        disabled={user}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                사업자종류 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                {!user ? (
                                    <RadioGroup control={control} data={!groupIsLoading && groupCode?.MEMBER_TYPE} name="coType" />
                                ) : (
                                    <Typography>{mydata?.content?.coType === "MT_01" ? "법인사업자" : "개인사업자"}</Typography>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                사업자등록번호 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <Controller
                                        name="saupNum"
                                        control={control}
                                        render={({ field }) => (
                                            <BusinessRegistrationInput
                                                loading={resultAction?.isLoading || undefined}
                                                size={"small"}
                                                inputProps={{ maxLength: 12 }}
                                                sx={{ fontSize: "13px" }}
                                                disabled={user && true}
                                                {...field}
                                            />
                                        )}
                                    />
                                    {!user && (
                                        <CustomSubmitButton type={"button"} onClick={handleSaupNumDuplicate} text="중복확인" size={"sm"} />
                                    )}
                                </div>
                                <p className="bdy_desc">* 본사의 사업자등록번호로 등록하여 주세요.(여러개의 사업자등록번호가 있는 경우)</p>
                            </td>
                        </tr>
                        {watch("coType") === "MT_01" && (
                            <tr>
                                <td className="hdr">
                                    법인번호 <span className="reqItem">*</span>
                                </td>
                                <td className="bdy">
                                    <div className="bdy_inn bdy_flex">
                                        <TextInput
                                            control={control}
                                            name="lawNum01"
                                            maxLength="6"
                                            placeholder="법인번호 앞 6자리 입력하세요."
                                            type="number"
                                            width="100%"
                                            disabled={user}
                                        />
                                        <span className="line_input">-</span>
                                        <TextInput
                                            control={control}
                                            name="lawNum02"
                                            maxLength="7"
                                            placeholder="법인번호 뒤 7자리 입력하세요."
                                            type="number"
                                            width="100%"
                                            disabled={user}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td className="hdr">
                                대표자성명 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex res_flex">
                                    <TextInput
                                        control={control}
                                        name="ceoName"
                                        maxLength="30"
                                        placeholder="대표자성명을 입력하세요."
                                        type="text"
                                        width="400px"
                                        disabled={user}
                                    />
                                    <p className="bdy_desc">* 대표자명은 공백없이 기업하여 주세요.</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                대표자생년월일 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex res_flex">
                                    <TextInput
                                        control={control}
                                        name="ceoBirth"
                                        maxLength="6"
                                        placeholder="대표자성명을 입력하세요."
                                        type="number"
                                        width="400px"
                                    />
                                    <p className="bdy_desc">* 주민번호 앞자리 6자리로 입력해주세요.</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                대표자성별 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <RadioGroup control={control} data={!groupIsLoading && groupCode?.USR_GNDR} name="ceoGender" />
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                대표자 이메일 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex" style={{ alignItems: "flex-start" }}>
                                    <TextInput
                                        control={control}
                                        name="ceoEmail"
                                        maxLength="100"
                                        placeholder="대표자 이메일을 입력하세요."
                                        width="400px"
                                        type="email"
                                        onChange={handleChangeEmail}
                                    />
                                    {ceoEmailChanged && (
                                        <CustomSubmitButton type={"button"} onClick={handleCeoEmailDuplicate} text="중복확인" size={"sm"} />
                                    )}
                                </div>
                                <p className="bdy_desc">
                                    * 다음 한메일(hanmail.net/daum.net)은 수신이 원활하지 않을 수 있습니다. 가급적 다른 메일 사용을
                                    권장합니다.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                대표자 핸드폰번호 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <Select
                                        control={control}
                                        options={(!groupIsLoading && groupCode?.PHONE_TYPE) || []}
                                        name="ceoPhoneStart"
                                        defaultOption={"select"}
                                        width="100px"
                                        moWidth="80px"
                                    />
                                    <span className="line_input">-</span>
                                    <TextInput
                                        control={control}
                                        name="ceoPhoneMiddle"
                                        maxLength="4"
                                        type="number"
                                        onKeyUp={(e) => {
                                            if (e.target.value.length > 3) {
                                                document.getElementById("ceoPhoneEnd").focus();
                                            }
                                        }}
                                        width="100px"
                                        moWidth="60px"
                                    />
                                    <span className="line_input">-</span>
                                    <TextInput
                                        control={control}
                                        id="ceoPhoneEnd"
                                        name="ceoPhoneEnd"
                                        onKeyUp={(e) => {
                                            if (e.target.value.length > 3) {
                                                document.getElementById("writerName").focus();
                                            }
                                        }}
                                        maxLength="4"
                                        type="number"
                                        width="100px"
                                        moWidth="60px"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                담당자 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex res_flex">
                                    <TextInput
                                        control={control}
                                        id="writerName"
                                        name="writerName"
                                        maxLength="50"
                                        placeholder="담당자명을 입력하세요."
                                        width="400px"
                                        trim="true"
                                    />
                                    <p className="bdy_desc">
                                        * 담당자는 대표 및 연구소장 등 책임자로 기입하여 주세요.
                                        <br /> * 담당자명은 직책을 포함하여 기입하여 주세요.
                                        <span style={{ color: "#000" }}>예) 홍길동 (소장)</span>
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                담당자 이메일 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex" style={{ alignItems: "flex-start" }}>
                                    <TextInput
                                        control={control}
                                        name="writerEmail"
                                        maxLength="100"
                                        placeholder="담당자 이메일을 입력하세요."
                                        width="400px"
                                        type="email"
                                        onChange={handleChangeWriterEmail}
                                    />
                                    {writerEmailChanged && (
                                        <CustomSubmitButton
                                            type={"button"}
                                            onClick={handleWriterEmailDuplicate}
                                            text="중복확인"
                                            size={"sm"}
                                        />
                                    )}
                                </div>
                                <p className="bdy_desc">
                                    * 다음 한메일(hanmail.net/daum.net)은 수신이 원활하지 않을 수 있습니다. 가급적 다른 메일 사용을
                                    권장합니다.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                이메일수신동의여부 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <RadioGroup control={control} data={!groupIsLoading && groupCode?.AGREE_OR_NOT} name="emailYn" />
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                팩스수신동의여부 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <RadioGroup control={control} data={!groupIsLoading && groupCode?.AGREE_OR_NOT} name="faxYn" />
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                비밀번호 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <TextInput
                                        control={control}
                                        name="password01"
                                        maxLength="100"
                                        placeholder="비밀번호를 입력하세요."
                                        width="400px"
                                        type="password"
                                    />
                                </div>
                                <p className="bdy_desc mt-5">
                                    * 비밀번호는 10자이상 15자이내에 기입하여 주세요.
                                    <br />* 영대문자, 영소문자, 숫자, 특수문자를 모두 조합하여 입력해주세요.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                비밀번호확인 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <TextInput
                                        control={control}
                                        name="password02"
                                        maxLength="100"
                                        placeholder="비밀번호확인을 입력하세요."
                                        width="400px"
                                        type="password"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                설립일 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <DatePicker width={250} disableFuture={true} control={control} name="coDate" />
                                </div>
                                <p className="bdy_desc">
                                    * 법인사업자는 법인등기사항증명서상의 설립일, 개인사업자는 사업자등록증상의 개업일을 입력하여주세요.{" "}
                                    <br />* 법인전환의경우 개인사업자 개업일을 입력하여 주세요.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                전화번호 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <Select
                                        control={control}
                                        options={(!groupIsLoading && groupCode?.PHONE_TYPE) || []}
                                        name="phoneStart"
                                        defaultOption={"select"}
                                        width="100px"
                                        moWidth="80px"
                                    />
                                    <span className="line_input">-</span>
                                    <TextInput
                                        control={control}
                                        id="phoneMiddle"
                                        name="phoneMiddle"
                                        maxLength="4"
                                        onKeyUp={(e) => {
                                            if (e.target.value.length > 3) {
                                                document.getElementById("phoneEnd").focus();
                                            }
                                        }}
                                        type="number"
                                        width="100px"
                                        moWidth="60px"
                                    />
                                    <span className="line_input">-</span>
                                    <TextInput
                                        control={control}
                                        id="phoneEnd"
                                        name="phoneEnd"
                                        onKeyUp={(e) => {
                                            if (e.target.value.length > 3) {
                                                document.getElementById("faxStart").focus();
                                            }
                                        }}
                                        maxLength="4"
                                        type="number"
                                        width="100px"
                                        moWidth="60px"
                                    />
                                </div>
                                <p className="bdy_desc">* 반드시 본사를 입력하여 주세요. (법인등기사항증명서상의 본사)</p>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                FAX <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <Select
                                        control={control}
                                        options={(!groupIsLoading && groupCode?.PHONE_TYPE) || []}
                                        id="faxStart"
                                        name="faxStart"
                                        defaultOption={"select"}
                                        width="100px"
                                        moWidth="80px"
                                    />
                                    <span className="line_input">-</span>
                                    <TextInput
                                        control={control}
                                        name="faxMiddle"
                                        maxLength="4"
                                        onKeyUp={(e) => {
                                            if (e.target.value.length > 3) {
                                                document.getElementById("faxEnd").focus();
                                            }
                                        }}
                                        type="number"
                                        width="100px"
                                        moWidth="60px"
                                    />
                                    <span className="line_input">-</span>
                                    <TextInput
                                        control={control}
                                        id="faxEnd"
                                        name="faxEnd"
                                        maxLength="4"
                                        type="number"
                                        width="100px"
                                        moWidth="60px"
                                    />
                                </div>
                                <p className="bdy_desc">* 반드시 본사를 입력하여 주세요. (법인등기사항증명서상의 본사)</p>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                주소 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn">
                                    <div className="zip_search">
                                        <TextInput control={control} name="coZip" placeholder="우편번호" disabled="true" width="100px" />
                                        {!user && (
                                            <CustomSubmitButton
                                                type={"button"}
                                                onClick={() => setAddressModal(true)}
                                                text={"우편번호찾기"}
                                                size={"sm"}
                                            />
                                        )}
                                    </div>
                                    <TextInput
                                        control={control}
                                        name="coAddr1"
                                        placeholder="주소"
                                        disabled="true"
                                        style={{ margin: "5px 0" }}
                                    />
                                    <TextInput control={control} name="coAddr2" placeholder="상세 주소를 입력하세요" disabled={user} />
                                </div>
                                <p className="bdy_desc">* 반드시 본사를 입력하여 주세요. (법인등기사항증명서상의 본사)</p>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                홈페이지 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <TextInput
                                        control={control}
                                        name="coUrl"
                                        maxLength="200"
                                        placeholder="홈페이지 URL을 입력하세요."
                                        width="400px"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                상장여부 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <RadioGroup control={control} data={!groupIsLoading && groupCode?.SANJANG} name="coSanjang" />
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                자본금 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <TextInput
                                        control={control}
                                        name="coBaseMoney"
                                        maxLength="21"
                                        type="money"
                                        width="400px"
                                        placeholder={"숫자만 입력하여 주세요."}
                                    />
                                    원
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                관련업종 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <Select
                                        control={control}
                                        options={(!groupIsLoading && groupCode?.RELATED_INDUSTRIES) || []}
                                        name="coCategory"
                                        defaultOption={"select"}
                                        width="200px"
                                        moWidth="80px"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                과학기술표준분류코드 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <TextInput control={control} name="coCategoryCode" width="100px" disabled="true" />
                                    <TextInput control={control} name="coCategoryDescr" width="200px" disabled="true" />
                                    <CustomSubmitButton
                                        type={"button"}
                                        onClick={() => {
                                            setIndustryType1(null);
                                            setIndustryType2(null);
                                            setIndustryType3(null);
                                            setShowApiModal(true);
                                        }}
                                        text="검색"
                                        size={"sm"}
                                    />
                                </div>
                                {/* <p className="bdy_desc">* 업종코드(평가지표)는 재무사항입력 이후부터는 수정하실 수 없습니다.</p> */}
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                결산월 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <Select
                                        control={control}
                                        options={(!groupIsLoading && groupCode?.MONTH) || []}
                                        name="accountMonth"
                                        defaultOption={"select"}
                                        width="100px"
                                        moWidth="80px"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                재무제표확정여부 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <Typography>당기 재무제표를 확정신고 하셨습니까?</Typography>
                                    <RadioGroup control={control} data={!groupIsLoading && groupCode?.YES_OR_NO} name="financialFix" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                재무제표보유여부 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <Typography>최근 2년간 재무제표를 확정하여 보유하고 있습니까? </Typography>
                                    <RadioGroup control={control} data={!groupIsLoading && groupCode?.YES_OR_NO} name="financialKeep" />
                                </div>
                            </td>
                        </tr>
                        {/*<tr>
                            <td className="hdr">
                                보증희망여부 <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <RadioGroup control={control} data={!groupIsLoading && groupCode?.YES_OR_NO} name="financialGuaranty" />
                                </div>
                            </td>
                        </tr>*/}
                        <tr>
                            <td className="hdr">
                                과학기술혁신기업협회 회원가입
                                <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <RadioGroup control={control} data={!groupIsLoading && groupCode?.YES_OR_NO} name="membershipYn" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">
                                이공계 인력중개 신청
                                <span className="reqItem">*</span>
                            </td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <RadioGroup control={control} data={!groupIsLoading && groupCode?.YES_OR_NO} name="engineeringYn" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="hdr">추천기관 코드</td>
                            <td className="bdy">
                                <div className="bdy_inn bdy_flex">
                                    <TextInput
                                        control={control}
                                        name="referralCode"
                                        maxLength="6"
                                        placeholder="추천기관 코드를 입력하세요."
                                        width="400px"
                                        type="number"
                                    />
                                </div>
                                <p className="bdy_desc">* 인증 추천기관이 있으시면, 6자리 코드를 입력하여 주세요</p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="btn_wrap">
                    <CustomSubmitButton
                        type="button"
                        onClick={handleSubmitButton}
                        className="step_next_button"
                        text={user ? "저장하기" : "저장하고 다음으로 넘어가기"}
                        size="lg"
                    />
                </div>
            </form>

            {checkRequiredItems && (
                <ConfirmModal
                    open={showConfirmModal}
                    close={() => setShowConfirmModal(false)}
                    onClick={handleSubmit(submit)}
                    title="안내"
                    text={!user ? "가입등록을 하시겠습니까?" : "기본 정보 수정하시겠습니까?"}
                    loading={resultAction?.isLoading || resultActionUpdate?.isLoading}
                    disabled={resultAction?.isLoading || resultActionUpdate?.isLoading}
                />
            )}

            <AlertModal
                open={showAlertModal}
                close={() => setShowAlertModal(false)}
                onClick={() => setShowAlertModal(false)}
                title={"안내"}
                text={alertModalContent}
                loading={resultAction?.isLoading || resultActionUpdate?.isLoading}
                disabled={resultAction?.isLoading || resultActionUpdate?.isLoading}
            />

            <BasicModal open={addressModal} close={() => setAddressModal(false)} width={{ xs: "95%", md: 800 }}>
                <DaumPostcode onComplete={handleAddressComplete} autoClose style={{ height: "445px" }} />
            </BasicModal>

            <CustomModal
                open={showApiModal}
                onClose={() => setShowApiModal(false)}
                width={{ xs: "95%", sm: "95%", md: "85%" }}
                title="과학기술표준분류코드 검색"
            >
                <Grid container mt={"30px"} gap={"20px"} flexWrap={"nowrap"} sx={{ minHeight: "600px" }}>
                    <Grid item xs={6}>
                        <IndustryTable
                            code={null}
                            type={1}
                            setCode={(e) => {
                                setIndustryType1(e);
                                setIndustryType2(null);
                                setIndustryType3(null);
                                handleChangeIndustryType3("", "");
                            }}
                            title={"대분류"}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <IndustryTable
                            code={industryType1}
                            type={2}
                            setCode={(e) => {
                                setIndustryType2(e);
                                setIndustryType3(null);
                                handleChangeIndustryType3("", "");
                            }}
                            title={"중분류"}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <IndustryTable
                            code={industryType2}
                            type={3}
                            setCode={(e) => {
                                handleChangeIndustryType3(e.code, e.name);
                                setIndustryType3(e);
                            }}
                            title={"소분류"}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} textAlign={"center"} display={"flex"} justifyContent={"center"} gap={"10px"}>
                    <Grid item>
                        선택한 과학기술표준분류코드 :
                        <input
                            type="text"
                            className={"custom_input"}
                            readOnly={true}
                            value={(industryType3?.code && industryType3?.code) || ""}
                        />
                    </Grid>
                    <Grid item>
                        <input
                            type="text"
                            className={"custom_input"}
                            readOnly={true}
                            value={(industryType3?.name && industryType3?.name) || ""}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={"30px"} textAlign={"center"} display={"flex"} justifyContent={"center"} gap={"10px"}>
                    <Button
                        disableElevation
                        variant="outlined"
                        color="primary"
                        disabled={resultAction?.isLoading || resultActionUpdate?.isLoading}
                        onClick={() => {
                            setShowApiModal(false);
                        }}
                        sx={{ padding: "5px 30px" }}
                    >
                        취소
                    </Button>
                    <LoadingButton
                        loading={resultAction?.isLoading || resultActionUpdate?.isLoading}
                        disabled={resultAction?.isLoading || resultActionUpdate?.isLoading}
                        variant="contained"
                        onClick={() => {
                            setShowApiModal(false);
                        }}
                        sx={{ padding: "5px 30px" }}
                    >
                        확인
                    </LoadingButton>
                </Grid>
            </CustomModal>
        </div>
    );
};
export default Application;
