import React from "react";
import { reduceByCertCategorySeqAndStageType } from "utils/functions/common";
import EvaluationCreateFileInput from "./evaluationCreateFileInput";
import { CustomSubmitButton } from "components/buttons/CustomButton";

const EvaluationCreateTable = ({
    userCertRate,
    certCategory,
    groupCode,
    isLoading,
    setShowApiModal,
    list,
    sendList,
    deleteFileSeq,
    setDeleteFileSeq
}) => {
    const renderedCats = [];
    let arr = [];
    const handleList = (e) => {
        if (arr.find((item) => item.certCategorySeq === e.certCategorySeq)) {
            arr = arr.map((item) => (item.certCategorySeq === e.certCategorySeq ? { ...item, files: e.files } : item));
        } else {
            arr.push(e);
        }
        sendList([...list, ...arr]);
    };

    return (
        <>
            <table>
                <caption>기업등록</caption>
                <tbody>
                    <tr>
                        <td className="hdr center">구분</td>
                        <td className="hdr center">세부평가항목</td>
                        <td className="hdr center">증빙자료</td>
                        <td className="hdr center">
                            평가자료 업로드
                            <br />
                            (PDF 파일 업로드)​​
                        </td>
                    </tr>
                    {!isLoading &&
                        certCategory?.data?.map((item, i) => {
                            let rate = userCertRate?.filter((cert) => cert.certCategorySeq == item.seq)[0] || null;
                            let formList = reduceByCertCategorySeqAndStageType(list);
                            let file = [];
                            if (formList) {
                                file = formList?.find((m) => m.certCategorySeq == item.seq)?.files;
                            }

                            const handleDeleteFile = (seq, fileName, delSeq) => {
                                if (delSeq > 0) {
                                    rate = rate?.attachments?.filter((file) => file.fileSeq != delSeq);
                                    setDeleteFileSeq([...deleteFileSeq, delSeq]);
                                } else {
                                    const updatedList = list
                                        .map((item) => {
                                            if (item.certCategorySeq === seq) {
                                                const filteredFiles = item.files.filter((file) => file.name !== fileName);
                                                return {
                                                    ...item,
                                                    files: [...filteredFiles]
                                                };
                                            }
                                            return item;
                                        })
                                        .filter((item) => item.files.length > 0);

                                    sendList([...updatedList]);
                                }
                            };

                            if (!renderedCats.includes(item.certCategoryCd)) {
                                renderedCats.push(item.certCategoryCd);
                                const rowCount = certCategory.data.filter((m) => m.certCategoryCd === item.certCategoryCd).length;
                                const categoryCode = groupCode?.data?.find((code) => code.code === item.certCategoryCd)?.codeNm;
                                return (
                                    <tr key={i}>
                                        {categoryCode && <td rowSpan={rowCount}>{categoryCode}</td>}
                                        <td>{item.ratingName}</td>
                                        <td>
                                            {item.supportingMaterialName}{" "}
                                            {item.scoreDesc && <span className="bs_green">{item.scoreDesc}</span>}
                                        </td>
                                        <td>
                                            {item.ratingName == "기술 인력 교육 및 교육훈련 지원 비율" ? (
                                                <CustomSubmitButton
                                                    onClick={() => {
                                                        setShowApiModal(true);
                                                    }}
                                                    className="step_next_button"
                                                    text="국가연구자 정보 불러 오기"
                                                    sx={{ fontSize: "14px" }}
                                                    disabled={false}
                                                />
                                            ) : item.ratingName !== "연구개발 예산 및 투자율" ? (
                                                <EvaluationCreateFileInput
                                                    item={item}
                                                    data={rate ?? file}
                                                    setObj={(e) => {
                                                        handleList(e);
                                                    }}
                                                    idx={i}
                                                    deleteFile={(seq, fileName, deleteFileSeq) =>
                                                        handleDeleteFile(seq, fileName, deleteFileSeq)
                                                    }
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </td>
                                    </tr>
                                );
                            } else {
                                return (
                                    <tr key={i}>
                                        <td>{item.ratingName}</td>
                                        <td>
                                            {item.supportingMaterialName}{" "}
                                            {item.scoreDesc && <span className="bs_green">{item.scoreDesc}</span>}
                                        </td>
                                        <td>
                                            {item.ratingName == "기술 인력 교육 및 교육훈련 지원 비율" ? (
                                                <CustomSubmitButton
                                                    onClick={() => {
                                                        setShowApiModal(true);
                                                    }}
                                                    className="step_next_button"
                                                    text="국가연구자 정보 불러 오기"
                                                    sx={{ fontSize: "14px" }}
                                                    disabled={false}
                                                />
                                            ) : item.ratingName !== "연구개발 예산 및 투자율" ? (
                                                <EvaluationCreateFileInput
                                                    item={item}
                                                    data={rate ?? file}
                                                    setObj={(e) => {
                                                        handleList(e);
                                                    }}
                                                    idx={i}
                                                    deleteFile={(seq, fileName, deleteFileSeq) =>
                                                        handleDeleteFile(seq, fileName, deleteFileSeq)
                                                    }
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </td>
                                    </tr>
                                );
                            }
                        })}
                </tbody>
            </table>
        </>
    );
};

export default EvaluationCreateTable;
