import React, { lazy } from "react";
import Loadable from "components/Loadable";
import { ApplicationApi } from "api/application/index";
const EvaluationDataForm = Loadable(lazy(() => import("./evaluationDataForm")));

const EvaluationDataConfig = {
    routes: [
        {
            path: "",
            children: [
                {
                    path: "evaluation",
                    element: <EvaluationDataForm />
                }
            ]
        }
    ],
    API: ApplicationApi
};

export default EvaluationDataConfig;
