import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DefaultDateInput } from './default';

const DatePicker = forwardRef(({ control, error, ...props }, ref) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
                ref={ref}
                name={props?.name}
                disabled={props?.disabled}
                control={control}
                render={({ field }) => <DefaultDateInput {...field} {...props} error={error} />}
            />
        </LocalizationProvider>
    );
});

export default DatePicker;
