import React, { lazy } from "react";
import Pages from "store/pages";
import Loadable from "components/Loadable";
import { CommunityApi } from "api/community/community";
import { NewProvider } from "./news/context";
import { EventProvider } from "./event/context";

const NewsPage = Loadable(lazy(() => import("./news/index")));
const NewsDetailPage = Loadable(lazy(() => import("./news/detail")));
const EventPage = Loadable(lazy(() => import("./event/index")));
const EventDetailPage = Loadable(lazy(() => import("./event/detail")));

const CommunityConfig = {
    routes: [
        {
            path: Pages.COMMUNITY_NEWS,
            element: (
                <NewProvider>
                    <NewsPage />
                </NewProvider>
            )
        },
        {
            path: Pages.COMMUNITY_NEWS_DETAIL,
            element: (
                <NewProvider>
                    <NewsDetailPage />
                </NewProvider>
            )
        },
        {
            path: Pages.COMMUNITY_EVENT,
            element: (
                <EventProvider>
                    <EventPage />
                </EventProvider>
            )
        },
        {
            path: Pages.COMMUNITY_EVENT_DETAIL,
            element: (
                <EventProvider>
                    <EventDetailPage />
                </EventProvider>
            )
        }
    ],
    API: CommunityApi
};

export default CommunityConfig;
