import React from 'react';
import DataTable from 'react-data-table-component';
import Loading from 'components/Loading';

const ListDataTable = ({ ...props }) => {
    const customStyles = {
        rows: {
            style: {
                minHeight: '67px', // override the row height
            }
        },
        cells: {
            style: {
                fontSize: '14px',
                cursor: 'auto',
                whiteSpace: "nowrap", 
                width: "115px",      
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        },
        head: {
            style: {
                borderTop: '1px solid #333',
                borderBottom: '1px solid #00000066'
            }
        },
        headRow: {
            style: {
                fontSize: '14px',
                color: '#212529',
                fontWeight: '600',
                minHeight: 'auto',
            }
        },
        headCells: {
            style: {
                padding: '19px 5px',
                fontWeight: 600,
                color: '#222',
                justifyContent: "center",
            }
        },
        nav : {
            style: {
                borderTop: 'none'
            }
        },
    };

    const NoDataWithHeaders = ({columns}) => (
        <table style={{width: "100%"}}>
            <thead style={{lineHeight: '17px'}}>
            {columns?.map((item, i) => (
                <th key={i} style={{width: item.width ? item.width : 'unset' ,
                    border: 'none',
                    padding: '19px 5px',
                    borderTop: '1px solid #333',
                    borderBottom: '1px solid #00000066',
                    fontSize: '14px'
                }}>{item.name}</th>
            ))}
            </thead>
            <tbody>
                <tr>
                    <td colSpan={columns?.length} style={{textAlign: "center"}}><p style={{padding: '20px', fontWeight: '600'}}>No data</p></td>
                </tr>
            </tbody>
        </table>
    );

    return (
        <DataTable
            progressComponent={<Loading />}
            customStyles={customStyles}
            pagination
            paginationServer
            paginationRowsPerPageOptions={[5,10,20,50]}
            sortServer
            cus
            noDataComponent={<NoDataWithHeaders columns={props?.columns} />}
            paginationComponentOptions={{
                rowsPerPageText: '페이지당:',
                rangeSeparatorText: 'of'
            }}
            {...props}
        />
    );
};

export default ListDataTable;
