import { MainConfig, AuthConfig, CommunityConfig, ApplicationConfig } from "views/pages";

import { CommonCodeFilterApi } from "api/commonCode";
import { BannerApi } from "api/main/banner";
import { UserAddressApi } from "api/userAddress/index";
import { CertCategoryApi } from "api/cert/certCategory";
import { OrderApi } from "api/order/order";
import { PaymentApi } from "api/payment/payment";

export const Middlewares = [
    MainConfig.API.middleware,
    AuthConfig.API.middleware,
    CommunityConfig.API.middleware,
    CommonCodeFilterApi.middleware,
    BannerApi.middleware,
    UserAddressApi.middleware,
    ApplicationConfig.API.middleware,
    CertCategoryApi.middleware,
    PaymentApi.middleware,
    OrderApi.middleware
];
