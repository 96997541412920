import React, { useCallback, useEffect, useState } from "react";
import Steps from "../../step/steps";
import { CustomButton, CustomSubmitButton } from "components/buttons/CustomButton";
import { ConfirmModal } from "components/index";
import AlertModal from "components/modal/AlertModal";
import { useGetCertCategoriesByTypeQuery } from "api/cert/certCategory";
import {
    useCreateUserCertRateBulkMutation,
    useGetUserCertRateQuery,
    useGetUserInfoQuery,
    useUpdateUserCertRateBulkMutation
} from "api/application";
import { useGetCommonFilterCodeQuery } from "api/commonCode";
import { reduceByCertCategorySeqAndStageType } from "utils/functions/common";
import EligibilityTable from "./eligibilityTable";
import EligibilityCreateTable from "./eligibilityCreateTable";
import { useNavigate } from "react-router";
import Loader from "components/Loader";

const EligibilityForm = () => {
    const navigate = useNavigate();
    const { data, isLoading } = useGetUserInfoQuery();
    const { data: certCategory2, isCertCategory2Loading } = useGetCertCategoriesByTypeQuery("stageType=2");
    const { data: userCertRate, isUserCertRateLoading } = useGetUserCertRateQuery();
    const { data: groupCode, isLoading: groupIsLoading } = useGetCommonFilterCodeQuery("CERT_CATEGORY_CD");
    const [onAction, resultAction] = useCreateUserCertRateBulkMutation();
    const [onUpdateAction, resultUpdateAction] = useUpdateUserCertRateBulkMutation();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showReturnAlertModal, setShowReturnAlertModal] = useState(false);
    const [alertModalContent, setAlertModalContent] = useState("");
    const [deleteFileSeq, setDeleteFileSeq] = useState([]);
    const [list, setList] = useState([]);
    const [dataYn, setDataYn] = useState(false);
    const [point, setPoint] = useState(0);
    const [stageType1, setStageType1] = useState(0);

    useEffect(() => {
        if (userCertRate?.content?.length > 0) {
            if (userCertRate?.content?.filter((item) => item.stageType == 2)?.length > 0) {
                setDataYn(true);
            }
            setPoint(
                userCertRate?.content
                    ?.filter((item) => item.stageType === 2)
                    ?.reduce((sum, item) => parseInt(sum) + parseInt(item.sectionPoint), 0)
            );
            
            setStageType1(
                userCertRate?.content
                    ?.filter((item) => item.stageType === 1)
                    ?.reduce((sum, item) => {
                        const itemPoint = parseInt(item.sectionPoint) || 0;
                        return sum + itemPoint;
                    }, 0) || 0
            );

            if (stageType1 < 550) {
                setShowAlertModal(true);
            }
        }
        
    }, [userCertRate, stageType1]);

    const submit = useCallback(() => {
        let formList = reduceByCertCategorySeqAndStageType(list);
        let formData = new FormData();
        for (let i = 0; i < certCategory2?.data?.length; i++) {
            let item = formList.find((item) => item.certCategorySeq === certCategory2?.data[i]?.seq);
            if (item) {
                formData.append("form[" + i + "].seq", item.seq || 0);
                formData.append("form[" + i + "].certCategorySeq", item.certCategorySeq);
                formData.append("form[" + i + "].stageType", item.stageType);
                formData.append("form[" + i + "].fileGroupSeq", item.fileGroupSeq);
                for (let j = 0; j < item.files?.length; j++) {
                    formData.append("form[" + i + "].files[" + j + "]", item.files[j]);
                }
            } else {
                formData.append("form[" + i + "].certCategorySeq", certCategory2?.data[i]?.seq);
                formData.append("form[" + i + "].stageType", certCategory2?.data[i]?.stageType);
            }
        }
        for (let i = 0; i < deleteFileSeq.length; i++) {
            formData.append("deleteFileSeq[" + i + "]", deleteFileSeq[i]);
        }

        if (dataYn) {
            onUpdateAction(formData)
                .then((res) => {
                    setShowConfirmModal(false);
                    if (res.data.status === 200) {
                        setShowAlertModal(true);
                        setAlertModalContent("2단계 평가자료 제출되었습니다.");
                    } else {
                        setAlertModalContent("관리자 연락하시기 바랍니다.");
                    }
                })
                .catch((err) => console.log("useEvaluationUpdateMutation ERROR => ", err));
        } else {
            onAction(formData)
                .then((res) => {
                    setShowConfirmModal(false);
                    if (res.data.status === 200) {
                        setShowAlertModal(true);
                        setAlertModalContent("2단계 평가자료 제출되었습니다.");
                    } else {
                        setAlertModalContent("관리자 연락하시기 바랍니다.");
                    }
                })
                .catch((err) => console.log("useEvaluationCreateMutation ERROR => ", err));
        }
    }, [onAction, onUpdateAction, list, certCategory2, deleteFileSeq, userCertRate]);

    let userCertList = userCertRate?.content?.filter((item) => item.stageType == 2) || [];
    
    const handleCloseNoAccessModal = () => {
        setShowAlertModal(false);
        navigate("/mypage/evaluation");
    };

    if (stageType1 < 550 || userCertRate?.content?.filter((item) => item.stageType === 2 && (item.sectionPoint == null || item.sectionPoint != ''))?.length == 0) {
        return (
            <AlertModal
                open={showAlertModal}
                close={handleCloseNoAccessModal}
                onClick={handleCloseNoAccessModal}
                title={"안내"}
                text={"현재 1단계 평가 인증이 완료되지 않았습니다. \n 인증이 완료 후 2단계평가 신청이 가능합니다."}
            />
        );
    }

    return (
        <div className="content_inn container evaluation_data">
            {isCertCategory2Loading && <Loader />}
            <h4 className="sub_cos_title">2단계 평가</h4>
            {
                userCertRate?.content?.filter((item) => item.stageType === 2 && (item.sectionPoint == null || item.sectionPoint != ''))?.length > 0 ? (
                    point > 350 ? (
                        <div style={{ padding: "20px 20px", background: "#ffebcd" }}>
                            <span style={{ fontWeight: "bold" }}>"{data?.content?.coName} 님"</span>
                            귀사의 2단계 평가 결과 과학기술혁신기업(STI) 으로 <span style={{ color: "#4caf50" }}>최종확인</span> 되었습니다.
                            <div className="btn_wrap" style={{ justifyContent: "start", marginTop: "15px" }}>
                                <CustomButton
                                    link={"/mypage/certificate"}
                                    text="현판 및 인증서 신청하기"
                                    className="step_next_button"
                                    size={"md"}
                                />
                            </div>
                        </div>
                    ) : (
                        <div style={{ padding: "20px 20px", background: "#ffebcd" }}>
                            <span style={{ fontWeight: "bold" }}>"{data?.content?.coName} 님"</span>
                            은 <span style={{ color: "#4caf50" }}>{point || 0}</span> 점으로 2평가 인증자격기준 미달 입니다. 재평가 하시기 바랍니다.
                        </div>
                    )
                ) :(
                    <div style={{ padding: "20px 20px", background: "#ffebcd" }}>
                        <span style={{ fontWeight: "bold" }}>"{data?.content?.coName} 님"</span>
                        현재 <span style={{ color: "#4caf50" }}>“2단계 평가 대기중”</span> 입니다. (신청일로부터 3일이내 평가)
                    </div>
                )
            }
            {!isUserCertRateLoading && !isCertCategory2Loading && !dataYn ? (
                <EligibilityCreateTable
                    userCertRate={userCertList}
                    certCategory={certCategory2}
                    groupCode={groupCode}
                    isLoading={isCertCategory2Loading}
                    list={list}
                    sendList={setList}
                    deleteFileSeq={deleteFileSeq}
                    setDeleteFileSeq={setDeleteFileSeq}
                />
            ) : (
                <EligibilityTable
                    userCertRate={userCertList}
                    certCategory={certCategory2}
                    groupCode={groupCode}
                    isLoading={isCertCategory2Loading}
                    list={list}
                    sendList={setList}
                    deleteFileSeq={deleteFileSeq}
                    setDeleteFileSeq={setDeleteFileSeq}
                />
            )}

            <div className="btn_wrap">
                <CustomSubmitButton
                    onClick={() => setShowConfirmModal(true)}
                    className="step_next_button"
                    text="2단계 평가 신청하기"
                    size="lg"
                />
            </div>

            <ConfirmModal
                open={showConfirmModal}
                close={() => setShowConfirmModal(false)}
                onClick={() => submit()}
                title="안내"
                text={"2단계 평가자료 제출 하시겠습니까?"}
                loading={resultAction?.isLoading || resultUpdateAction?.isLoading}
                disabled={resultAction?.isLoading || resultUpdateAction?.isLoading}
            />

            <AlertModal
                open={showAlertModal}
                close={() => setShowAlertModal(false)}
                onClick={() => setShowAlertModal(false)}
                title={"안내"}
                text={alertModalContent}
                loading={resultAction?.isLoading || resultUpdateAction?.isLoading}
                disabled={resultAction?.isLoading || resultUpdateAction?.isLoading}
            />

            <AlertModal
                open={showReturnAlertModal}
                close={() => setShowReturnAlertModal(false)}
                onClick={() => {
                    navigate("/mypage/evaluation");
                }}
                title={"안내"}
                text={alertModalContent}
                loading={resultAction?.isLoading || resultUpdateAction?.isLoading}
                disabled={resultAction?.isLoading || resultUpdateAction?.isLoading}
            />
        </div>
    );
};
export default EligibilityForm;
