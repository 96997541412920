import React from 'react';
import EligibilityFileInput from './eligibilityFileInput';

const EligibilityTable = ({userCertRate, certCategory, groupCode, isLoading, list, sendList, deleteFileSeq, setDeleteFileSeq}) => {
    const renderedCats = [];
    let arr = [];
    const handleList = (e) => {
        if (arr.find(item => item.certCategorySeq === e.certCategorySeq)) {
            arr = arr.map(item =>
                item.certCategorySeq === e.certCategorySeq ? { ...item, files: e.files } : item
            );
        } else {
            arr.push(e);
        }
        sendList([...list, ...arr]);
    };

    const handleDeleteFile = (seq, fileName, delSeq) => {
        const filteredList = list.filter((item) => item.certCategorySeq == seq && item.files[0].name != fileName);
        if(delSeq) {
            setDeleteFileSeq([...deleteFileSeq, delSeq]);
        }
        sendList([...filteredList]);
    };
    
    return (
        <>
            <table>
                <caption>기업등록</caption>
                <tbody>
                <tr>
                    <td className="hdr center">구분</td>
                    <td className="hdr center">세부평가항목</td>
                    <td className="hdr center">증빙자료</td>
                    <td className="hdr center">
                        평가 자료 업로드2단계 ​
                        <br />
                        (PDF 파일 업로드)​​
                    </td>
                </tr>
                {!isLoading && certCategory?.data?.map((item, i) => {
                    if (!renderedCats.includes(item.certCategoryCd)) {
                        renderedCats.push(item.certCategoryCd);
                        const rowCount = certCategory.data.filter((m) => m.certCategoryCd === item.certCategoryCd).length;
                        const categoryCode = groupCode?.data?.find((code) => code.code === item.certCategoryCd)?.codeNm;

                        return (
                            <tr key={i}>
                                {categoryCode && <td rowSpan={rowCount}>{categoryCode}</td>}
                                <td>{item.ratingName}</td>
                                <td>{item.supportingMaterialName} {item.scoreDesc && <span className="bs_green">{item.scoreDesc}</span>}</td>
                                <td>
                                    {
                                        item.ratingName == '기술적 경쟁력 및 시장 점유율' ? (
                                            <EligibilityFileInput
                                                item={item}
                                                data={userCertRate?.filter((cert) => cert.certCategorySeq == item.seq)[0]}
                                                setData={(e) => {handleList(e)}}
                                                idx={i}
                                                deleteFile={(seq, fileName, deleteFileSeq) => handleDeleteFile(seq, fileName, deleteFileSeq)}
                                            />
                                        ) : (
                                            '평가위원 현장 인터뷰 평가'
                                        )
                                    }
                                </td>
                            </tr>
                        );
                    } else {
                        return (
                            <tr key={i}>
                                <td>{item.ratingName}</td>
                                <td>{item.supportingMaterialName} {item.scoreDesc && <span className="bs_green">{item.scoreDesc}</span>}</td>
                                <td>
                                    {
                                        item.ratingName == '기술적 경쟁력 및 시장 점유율' ? (
                                            <EligibilityFileInput
                                                item={item}
                                                data={userCertRate?.filter((cert) => cert.certCategorySeq == item.seq)[0]}
                                                setData={(e) => {handleList(e)}}
                                                idx={i}
                                                deleteFile={(seq, fileName, deleteFileSeq) => handleDeleteFile(seq, fileName, deleteFileSeq)}
                                            />
                                        ) : (
                                            '평가위원 현장 인터뷰 평가'
                                        )
                                    }
                                </td>
                            </tr>
                        );
                    }
                })}
                </tbody>
            </table>
        </>
    );
};

export default EligibilityTable;
