import { Grid, Modal, Typography } from "@mui/material";
import { ModalInner } from "./ModalInner";
import { LoadingButton } from "@mui/lab";
import ButtonWrapp from "components/buttons/ButtonWrapp";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const AlertModal = ({ open, close, onClick, text, title, textStyle, width }) => {
    return (
        <Modal open={open} onClose={close} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <ModalInner width={width}>
                <Grid container justifyContent="space-between" sx={{ borderBottom: "1px solid #333", paddingBottom: "10px" }}>
                    <Grid item>{title && <Typography variant="h3">{title}</Typography>}</Grid>
                    <Grid item onClick={close} sx={{ cursor: "pointer" }}>
                        <CloseIcon />
                    </Grid>
                </Grid>
                <Typography id="parent-modal-description" variant="modalText" sx={textStyle}>
                    {text}
                </Typography>
                <ButtonWrapp sx={{ justifyContent: "center" }}>
                    <LoadingButton variant="contained" autoFocus onClick={onClick} sx={{ padding: "5px 30px" }}>
                        확인
                    </LoadingButton>
                </ButtonWrapp>
            </ModalInner>
        </Modal>
    );
};

export default AlertModal;
