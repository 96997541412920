import React, { forwardRef } from "react";
import { FormControlLabel, RadioGroup as Group, Radio, Skeleton } from "@mui/material";
import i18next from "i18next";

const DefaultRadioGroup = forwardRef(({ loading, onChange, data, ...props }, ref) => {
    const currentLanguage = i18next.language;

    if (loading) return <Skeleton animation="wave" height={60} sx={{ borderRadius: "12px" }} />;

    return (
        <Group
            name={props?.name}
            defaultValue={props?.value}
            value={props?.value}
            onChange={onChange}
            sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap"
            }}
            {...props}
        >
            {data?.map(({ code, codeNm, codeEngNm }) => (
                <FormControlLabel key={code} value={code} label={currentLanguage === "en" ? codeEngNm : codeNm} control={<Radio />} />
            ))}
        </Group>
    );
});

export default DefaultRadioGroup;
