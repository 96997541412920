import { createApi } from "@reduxjs/toolkit/query/react";
import { Api } from "../index";
import { URL } from "store/urls";

export const ApplicationApi = createApi({
    reducerPath: "ApplicationApi",
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ["Application"],
    endpoints: (build) => ({
        getUserInfo: build.query({
            query: () => `${URL.USER_INFO}`,
            providesTags: ["USER_INFO"]
        }),

        // Financial
        getFinancial: build.query({
            query: () => `${URL.FINANCIAL_LIST}`,
            providesTags: ["FINANCIAL_DETAIL"]
        }),
        createFinancial: build.mutation({
            query: (body) => ({
                url: `${URL.FINANCIAL_CREATE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["FINANCIAL_DETAIL", "USER_INFO"]
        }),
        registerFinancial: build.mutation({
            query: (body) => ({
                url: `${URL.FINANCIAL_REGISTER}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["FINANCIAL_DETAIL", "USER_INFO"]
        }),
        updateFinancial: build.mutation({
            query: (body) => ({
                url: `${URL.FINANCIAL_UPDATE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["FINANCIAL_DETAIL", "USER_INFO"]
        }),

        // Self Diagnosis
        getSelfDiagnosis: build.query({
            query: () => `${URL.SELF_DIAGNOSIS}`,
            providesTags: ["SELF_DETAIL"]
        }),
        getSelfDiagnosisDetail: build.query({
            query: (params) => `${URL.SELF_DIAGNOSIS_DETAIL}/${params}`,
            providesTags: ["SELF_DETAIL"]
        }),
        createSelfDiagnosis: build.mutation({
            query: (body) => ({
                url: `${URL.SELF_DIAGNOSIS_CREATE}`,
                method: "POST",
                body
            }),
            invalidatesTags: ["SELF_DETAIL"]
        }),
        updateSelfDiagnosis: build.mutation({
            query: (body) => ({
                url: `${URL.SELF_DIAGNOSIS_UPDATE}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["SELF_DETAIL"]
        }),
        getUserCertRate: build.query({
            query: () => `${URL.USER_CERT_RATE_DETAIL}`,
            providesTags: ["USER_CERT_DETAIL"]
        }),
        getDefaultRegisteredUserCertRate: build.query({
            query: (params) => `${URL.USER_CERT_RATE_DETAIL_DEFAULT_REGISTERED}?${params}`,
            providesTags: ["USER_CERT_DETAIL_DEFAULT"]
        }),
        createUserCertRateBulk: build.mutation({
            query: (body) => ({
                url: `${URL.USER_CERT_RATE_CREATE_BULK}`,
                method: "POST",
                body
            }),
            invalidatesTags: ["USER_CERT_DETAIL", "USER_COMPANY_MEMBER_LIST"]
        }),
        registerUserCertRateBulk: build.mutation({
            query: (body) => ({
                url: `${URL.USER_CERT_RATE_REGISTER_BULK}`,
                method: "POST",
                body
            })
        }),
        updateUserCertRateBulk: build.mutation({
            query: (body) => ({
                url: `${URL.USER_CERT_RATE_UPDATE_BULK}`,
                method: "POST",
                body
            }),
            invalidatesTags: ["USER_CERT_DETAIL", "USER_COMPANY_MEMBER_LIST"]
        }),

        getUserCompanyMemberList: build.query({
            query: () => `${URL.USER_COMPANY_MEMBER_LIST}`,
            providesTags: ["USER_COMPANY_MEMBER_LIST"]
        }),
        // invoice emil update

        updateInvoiceEmail: build.mutation({
            query: (body) => ({
                url: `${URL.INVOICE_EMAIL}`,
                method: "POST",
                body
            }),
            invalidatesTags: ["USER_INFO"]
        })
    })
});

export const {
    useGetUserInfoQuery,

    useGetFinancialQuery,
    useRegisterFinancialMutation,
    useCreateFinancialMutation,
    useUpdateFinancialMutation,

    useGetSelfDiagnosisQuery,
    useGetSelfDiagnosisDetailQuery,
    useCreateSelfDiagnosisMutation,
    useUpdateSelfDiagnosisMutation,

    useGetUserCertRateQuery,
    useGetDefaultRegisteredUserCertRateQuery,
    useCreateUserCertRateBulkMutation,
    useRegisterUserCertRateBulkMutation,
    useUpdateUserCertRateBulkMutation,

    useGetUserCompanyMemberListQuery,

    useUpdateInvoiceEmailMutation
} = ApplicationApi;
