// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `#ffffff`,
	"primaryLight": `#e3f2fd`,
	"primary200": `#90caf9`,
	"primaryMain": `#2196f3`,
	"primaryDark": `#1e88e5`,
	"primary800": `#1565c0`,
	"secondaryLight": `#ede7f6`,
	"secondary200": `#b39ddb`,
	"secondaryMain": `#673ab7`,
	"secondaryDark": `#5e35b1`,
	"secondary800": `#4527a0`,
	"successLight": `#b9f6ca`,
	"success200": `#69f0ae`,
	"successMain": `#00e676`,
	"successDark": `#00c853`,
	"errorLight": `#ef9a9a`,
	"errorMain": `#f44336`,
	"errorDark": `#c62828`,
	"orangeLight": `#fbe9e7`,
	"orangeMain": `#ffab91`,
	"orangeDark": `#d84315`,
	"warningLight": `#fff8e1`,
	"warningMain": `#ffe57f`,
	"warningDark": `#ffc107`,
	"grey50": `#fafafa`,
	"grey100": `#f5f5f5`,
	"grey200": `#eeeeee`,
	"grey300": `#e0e0e0`,
	"grey500": `#9e9e9e`,
	"grey600": `#757575`,
	"grey700": `#616161`,
	"grey900": `#212121`,
	"darkPaper": `#111936`,
	"darkBackground": `#1a223f`,
	"darkLevel1": `#29314f`,
	"darkLevel2": `#212946`,
	"darkTextTitle": `#d7dcec`,
	"darkTextPrimary": `#bdc8f0`,
	"darkTextSecondary": `#8492c4`,
	"darkPrimaryLight": `#e3f2fd`,
	"darkPrimaryMain": `#2196f3`,
	"darkPrimaryDark": `#1e88e5`,
	"darkPrimary200": `#90caf9`,
	"darkPrimary800": `#1565c0`,
	"darkSecondaryLight": `#d1c4e9`,
	"darkSecondaryMain": `#7c4dff`,
	"darkSecondaryDark": `#651fff`,
	"darkSecondary200": `#b39ddb`,
	"darkSecondary800": `#6200ea`
};
export default ___CSS_LOADER_EXPORT___;
