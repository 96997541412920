import React, { lazy } from "react";
import Loadable from "components/Loadable";
import NotFound from "components/notFound";
import Pages from "store/pages";
import { ApplicationConfig, AuthConfig } from "views/index";
import CommunityConfig from "views/pages/community";
import selfDiagnosisConfig from "views/pages/selfDiagnosis";
import EvaluationDataConfig from "views/pages/evaluationData";
import PaymentConfig from "views/pages/payment";
import CertOrderConfig from "views/pages/certOrder";

const Main = Loadable(lazy(() => import("views/pages/main/main")));
const DynamicPage = Loadable(lazy(() => import("views/pages/dynamic/page")));

const MainRoutes = [
    // MAIN
    { path: Pages.MAIN, element: <Main /> },

    // AUTH
    { path: "", children: [...AuthConfig.routes] },

    // COSTUMER SERVICE
    { path: "", children: [...CommunityConfig.routes] },
    { path: "/web/contents/:id", element: <DynamicPage /> },

    { path: "", children: [...ApplicationConfig.routes] },
    { path: "", children: [...selfDiagnosisConfig.routes] },
    { path: "", children: [...EvaluationDataConfig.routes] },
    { path: "", children: [...PaymentConfig.routes] },
    { path: "", children: [...CertOrderConfig.routes] },
    // static pages

    // 404 Not Found 페이지
    { path: "*", element: <NotFound /> }
];

export default MainRoutes;
