import React from "react";
import { Modal } from "@mui/material";
import { ModalInner } from "./ModalInner";

export const BasicModal = ({ open, close, children, width }) => {
    return (
        <Modal open={open} onClose={close} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <ModalInner width={width}>{children}</ModalInner>
        </Modal>
    );
};
