export const CardStyle = {
    // sub_conts_inner: {
    //     width: "100%",
    //     flexWrap: "nowrap !important",
    // },
    // sub_card_wrap:  {
    //     paddingTop: "82px",
    //     width: "1171px",
    //     margin: "0 auto",
        
    // },
    // sub_card_content: {
    //     padding: "0",
    //     marginTop: "49px"
    // },
}