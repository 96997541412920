import React from "react";
import { Controller } from "react-hook-form";
import { DefaultRadioGroup } from "./default";

const RadioGroup = ({ name, control, data, ...props }) => {
    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field }) => <DefaultRadioGroup data={data?.length > 0 ? data : []} {...field} {...props} />}
            />
        </>
    );
};

export default RadioGroup;
