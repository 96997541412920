import React, { useEffect, useState } from "react";
import { Grid, Skeleton, FormHelperText, Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import CreateIcon from "@mui/icons-material/Create";
import AlertModal from "components/modal/AlertModal";
import { SERVER_URL } from "config";

const label = {
    position: "relative",
    cursor: "pointer"
};

const edit = {
    position: "absolute",
    right: "7px",
    bottom: "2px",
    padding: "7px",
    background: "#00000059",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center"
};

const ProfileImgUpload = ({ name, loading, onChange, inputProps = { accept: "" }, value, type, alt, size, error }) => {
    const { t } = useTranslation();
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [fileName, setFileName] = useState(value ? value.name : "");
    const [valueType, setValueType] = useState("file");

    useEffect(() => {
        // 단일 객체로 제공된 경우, MIME 타입으로 이미지 파일인지 확인
        if (value && value.type && value.type.startsWith("image")) {
            setValueType("img");
        }
        // 배열 형태로 제공된 경우, fileExt 속성으로 이미지 파일 확장자인지 확인
        else if (value && value[0] && (value[0].fileExt === "jpg" || value[0].fileExt === "jpeg" || value[0].fileExt === "png")) {
            setValueType("img");
        }
        // 위의 조건에 해당하지 않는 경우, 'file'로 설정
        else {
            setValueType("file");
        }
    }, [value]); // value 변경 시에만 실행

    const isValidFileType = (file) => {
        if (inputProps && inputProps.accept) {
            const fileType = file.type;
            return inputProps.accept.split(",").some((acceptedType) => acceptedType.trim() === fileType);
        }
        return true; // accept 속성이 없는 경우 모든 파일 유형 허용
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (inputProps && inputProps.accept) {
            if (file && isValidFileType(file)) {
                setFileName(file.name); // 파일 이름 상태 업데이트
                onChange(event); // 파일이 유효한 유형일 경우, 부모 컴포넌트의 onChange 핸들러를 호출
            } else {
                setShowAlertModal(true); // 유효하지 않은 파일 유형일 경우, 경고 모달 표시
                event.target.value = ""; // 파일 입력 초기화
                setFileName(""); // 파일 이름 상태 초기화
            }
        } else {
            setFileName(file.name); // 파일 이름 상태 업데이트
            onChange(event); // 부모 컴포넌트의 onChange 핸들러를 호출
        }
    };

    const handleCloseModal = () => {
        setShowAlertModal(false);
    };

    return loading ? (
        <Skeleton animation="wave" height={60} sx={{ borderRadius: "12px" }} />
    ) : (
        <Grid container justifyContent={"center"}>
            <input type="file" id={name || "thumbnail"} style={{ display: "none" }} multiple {...inputProps} onChange={handleFileChange} />
            <label htmlFor={name || "thumbnail"} style={label}>
                {valueType === "img" ? (
                    <Avatar
                        alt={value[0]?.fileNm || fileName}
                        src={type === "local" ? URL.createObjectURL(value) : SERVER_URL + value[0]?.url}
                        sx={{ width: 150, height: 150, borderRadius: "50%", border: "2px solid" }}
                    />
                ) : (
                    <Avatar sx={{ width: 150, height: 150, borderRadius: "50%", border: "2px solid" }} />
                )}
                <div style={edit}>
                    <CreateIcon sx={{ fontSize: "20px", color: "#fff" }} />
                </div>
            </label>
            {error && <FormHelperText error>{error || ""}</FormHelperText>}
            <AlertModal open={showAlertModal} close={handleCloseModal} onClick={handleCloseModal} text={t("only_image_upload")} />
        </Grid>
    );
};

export default ProfileImgUpload;
