import React from 'react';
import { Box, TableCell, styled } from '@mui/material';

const Cell = ({ children, ...props }) => {
    return (
        <StyledCell {...props}>
            <StyledCellBox {...props}>{children || ''}</StyledCellBox>
        </StyledCell>
    );
};

export default Cell;

const cellWidth = (size) => {
    const widthMapping = {
        mini: '30px',
        small: '60px',
        medium: '150px',
        large: '300px',

        w10: '10%',
        w20: '20%',
        w30: '30%',
        w40: '40%',
        w50: '50%'
    };

    return widthMapping[size] || 'auto';
};

const StyledCell = styled(TableCell)(({ theme, ...props }) => ({
    width: props?.size ? cellWidth(props?.size) : 'auto',
    padding: props.padding || '5px',
    height: '100%',
    color: '#494949',
    fontFamily: 'Noto Sans CJ Kkr',
    fontSize: '15px',
    minHeight: '60px',
    backgroundColor: props?.gray !== undefined ? '#F8F8F8' : '#fff',
    textAlign: 'center'
}));

const StyledCellBox = styled(Box)(({ theme, ...props }) => ({
    padding: '8px',
    display: 'flex',
    alignItems: props?.alignItems ? props?.alignItems : 'center',
    flexDirection: props?.flexdirection ? props?.flexdirection : 'row',
    gap: '5px',
    justifyContent: props?.center !== undefined ? 'center' : 'start'
}));
