import { createApi } from "@reduxjs/toolkit/query/react";
import { Api } from "./index";
import { URL } from "store/urls";

export const CommonCodeFilterApi = createApi({
    reducerPath: "CommonCodeFilterApi",
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ["CommonCodes"],
    endpoints: (build) => ({
        getCommonFilterCode: build.query({
            query: (codeGroup) => `${URL.COMMONCODE_FILTER_LIST}?codegroup=${codeGroup}`,
            providesTags: ["LIST"]
        }),
        getCommonByGroupCodes: build.query({
            query: (codeGroups) => ({
                url: `${URL.COMMONCODE_FILTER_BY_GROUP_LIST}`,
                params: { codeGroups: codeGroups.join(",") },
                method: "GET"
            }),
            providesTags: ["LIST"]
        })
    })
});

export const { useGetCommonFilterCodeQuery, useGetCommonByGroupCodesQuery } = CommonCodeFilterApi;
