import React, { useState, lazy, useEffect } from "react";
import Loader from "components/Loader";
import Routes from "routes";
import { useLocation } from "react-router";
import Loadable from "components/Loadable";

import { useGetMenusQuery, useGetSiteInfoQuery } from "api/main/main";

import { ThemeProvider } from "@mui/material/styles";
import { StyleSheetManager } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import ScrollToTop from "utils/scrolltoTop";
import i18n from "i18n";
import LocaleContext from "LocaleContext";
import AOS from "aos";

import themes from "themes";
// import SideMenu from "views/layout/sideMenu";

import "swiper/css";
import "swiper/css/pagination";
import "aos/dist/aos.css";
import "./assets/css/reset.css";
import "./assets/css/main.css";
import "./assets/css/sub.css";
import "./assets/css/common.css";
import "./assets/css/media.css";
import SubMenu from "views/layout/subMenu";
import MyPageSideMenu from "views/pages/mypage/myPageSideMenu";

const Header = Loadable(lazy(() => import("views/layout/header")));
const Footer = Loadable(lazy(() => import("views/layout/footer")));

const sideMenu = [
    "/mypage/application",
    "/mypage/selfdiagnosis",
    "/mypage/selfresult",
    "/mypage/financial",
    "/mypage/evaluation",
    "/mypage/paying",
    "/mypage/eligibility",
    "/mypage/certificate"
];
let hasSideMenu = true;

function App() {
    const location = useLocation();
    const [locale, setLocale] = useState(i18n.language);
    const [saupNum, setSaupNum] = useState("");
    const [isTerms, setIsTerms] = useState(false);
    const { data: siteInfo, loading } = useGetSiteInfoQuery();
    const { data: menuData, menuLoading } = useGetMenusQuery();
    const regex = /\/(\d+)$/;

    useEffect(() => {
        AOS.init();
    });

    let path = location.pathname.replace(regex, "");
    if (sideMenu.includes(path)) {
        hasSideMenu = true;
    } else {
        hasSideMenu = false;
    }

    const screenWidth = window.innerWidth;

    return (
        <LocaleContext.Provider value={{ locale, setLocale, saupNum, setSaupNum, isTerms, setIsTerms }}>
            <StyleSheetManager shouldForwardProp={isPropValid} disableVendorPrefixes={false}>
                <ThemeProvider theme={themes()}>
                    {(menuLoading || loading) && <Loader />}
                    <Header menuData={menuData} menuLoading={menuLoading} siteInfo={siteInfo?.content || null} />
                    <div className="contents_wrap">
                        {location.pathname != "/" && menuData ? <SubMenu menuData={menuData} location={location} /> : ""}
                        <ScrollToTop>
                            {hasSideMenu ? (
                                <div className="mypage_content container">
                                    {screenWidth > 600 ? (
                                        <div className="left">
                                            <MyPageSideMenu />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <div className="side_menu_detail">
                                        <Routes />
                                    </div>
                                </div>
                            ) : (
                                <Routes />
                            )}
                        </ScrollToTop>
                    </div>
                    {siteInfo?.content && <Footer data={siteInfo?.content} />}
                </ThemeProvider>
            </StyleSheetManager>
        </LocaleContext.Provider>
    );
}

export default App;
