import React from "react";
import { Button, CircularProgress, Grid, Modal, Typography } from "@mui/material";
import { ModalInner } from "./ModalInner";
import ButtonWrapp from "components/buttons/ButtonWrapp";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";

export const ConfirmModal = ({ open, close, onClick, text, title, desc, loading }) => {
    return (
        <Modal open={open} onClose={close} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <ModalInner>
                <Grid container justifyContent="space-between" sx={{ borderBottom: "1px solid #333", paddingBottom: "10px" }}>
                    <Grid item>{title && <Typography variant="h3">{title}</Typography>}</Grid>
                    <Grid item onClick={close} sx={{ cursor: "pointer" }}>
                        <CloseIcon />
                    </Grid>
                </Grid>
                <Typography id="parent-modal-description" variant="modalText">
                    {text}
                </Typography>
                {desc && (
                    <Typography
                        id="parent-modal-description"
                        variant="h4"
                        sx={{
                            textAlign: "center",
                            margin: "30px 0",
                            paddingTop: "15px",
                            borderTop: "1px solid #e4e4e4",
                            whiteSpace: "pre-wrap"
                        }}
                    >
                        {desc}
                    </Typography>
                )}

                <ButtonWrapp sx={{ justifyContent: "center" }}>
                    <Button
                        disableElevation
                        variant="outlined"
                        color="primary"
                        disabled={loading}
                        onClick={close}
                        sx={{ padding: "5px 30px" }}
                    >
                        취소
                    </Button>
                    <LoadingButton
                        // loading={loading}
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={20} color="light" /> : ""}
                        variant="contained"
                        onClick={onClick}
                        sx={{ padding: "5px 30px" }}
                        autoFocus
                    >
                        확인
                    </LoadingButton>
                </ButtonWrapp>
            </ModalInner>
        </Modal>
    );
};
