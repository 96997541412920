import React, { useEffect, useState } from "react";
import { Grid, Skeleton, Box } from "@mui/material";
import AlertModal from "components/modal/AlertModal";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import { SERVER_URL } from "config";

const MultipleFileUploader = ({ name, onChange, inputProps, attachments, deleteFile, disabled, maxLength, loading }) => {
    const [files, setFiles] = useState([]);
    const [alertText, setAlertText] = useState("");
    const [alertModalOpen, setAlertModalOpen] = useState(false);

    const handleFileUpload = (event) => {
        const fileList = event.target.files;
        const newFiles = [...files];

        if (inputProps?.allows && fileList) {
            if (fileList[0]?.name.split(".").pop() != inputProps.allows) {
                setAlertText("확장자가 ‘" + inputProps.allows.toLocaleUpperCase() + "’인 파일만 첨부 가능합니다.");
                setAlertModalOpen(true);
                return;
            }
        }
        if (inputProps?.maxuploadsize && fileList[0]?.size / (1024 * 1024 * 1024) > inputProps?.maxuploadsize) {
            setAlertText("파일은 최대 " + inputProps?.maxuploadsize * 1000 + "MB 개까지만 업로드할 수 있습니다.");
            setAlertModalOpen(true);
            return;
        }
        if (files?.filter((file) => file.fileName == fileList[0].name).length > 0) {
            setAlertText("같은 파일을 업로드할 수 없습니다.");
            setAlertModalOpen(true);
            event.target.value = null;
            return;
        }

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                newFiles.push({
                    file: reader.result,
                    fileName: file.name,
                    filesize: `${(file.size / (1024 * 1024)).toFixed(2)} MB`,
                    extension: file.name.split(".").pop()
                });

                if (newFiles.length + fileList.length > maxLength) {
                    setAlertText("파일은 최대 " + (maxLength - 1) + " 개까지만 업로드할 수 있습니다.");
                    setAlertModalOpen(true);
                    return;
                }
                setFiles(newFiles.slice(0, maxLength));

                event.target.value = null;
            };
        }

        onChange(fileList);
    };

    useEffect(() => {
        if (attachments && attachments.length > 0) {
            let temp = attachments.map((item) => {
                if (item.fileSeq) {
                    return {
                        seq: item.fileSeq,
                        fileName: item.fileNm,
                        file: SERVER_URL + item.url,
                        filesize: `${(item.fileSize / (1024 * 1024)).toFixed(2)} MB`,
                        extension: item.fileExt
                    };
                } else {
                    return {
                        file: item.result,
                        fileName: item.name,
                        filesize: `${(item.size / (1024 * 1024)).toFixed(2)} MB`,
                        extension: item.name.split(".").pop()
                    };
                }
            });
            setFiles(temp);
        } else {
            setFiles([]);
        }
    }, [attachments]);

    const handleDeleteFile = (fileName, seq) => {
        const filteredFiles = files?.filter((file) => file.fileName !== fileName);
        setFiles(filteredFiles);
        deleteFile(fileName, seq);
    };

    const handleAlertModalClose = () => {
        setAlertModalOpen(false);
    };

    return loading ? (
        <Skeleton animation="wave" height={60} sx={{ borderRadius: "12px" }} />
    ) : (
        <Grid
            container
            gap={1}
            alignItems="left"
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px"
            }}
        >
            <Grid item>
                {files.length > 0 ? (
                    files.map((item, index) => (
                        <Grid
                            container
                            key={index}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "nowrap",
                                gap: "10px"
                            }}
                        >
                            <Grid item sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                <Box
                                    sx={{
                                        border: "2px solid #3c3c6e",
                                        background: "#E0F8FE",
                                        borderRadius: "50%",
                                        minWidth: "30px",
                                        height: "30px",
                                        fontSize: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginRight: "10px"
                                    }}
                                >
                                    {item.extension}
                                </Box>
                                <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                    <Typography>{item.fileName}</Typography>
                                    <Typography>{item.filesize}</Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <ClearIcon
                                    sx={{ cursor: "pointer", color: "#f44336fa" }}
                                    onClick={() => handleDeleteFile(item.fileName, item.seq)}
                                />
                            </Grid>
                        </Grid>
                    ))
                ) : (
                    <p>업로드 된 파일이 없습니다.</p>
                )}
            </Grid>
            <Grid item>
                <Box sx={{ background: "transparent" }} position={"relative"}>
                    <input
                        type="file"
                        id={name || "thumbnail"}
                        style={{ display: "none" }}
                        multiple
                        disabled={disabled}
                        {...inputProps}
                        onChange={handleFileUpload}
                    />
                    <label htmlFor={name || "thumbnail"}>
                        <Box
                            sx={{
                                display: "inline-flex",
                                alignItems: "center",
                                cursor: "pointer",
                                padding: "6px 12px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                background: "#f5f5f5"
                            }}
                            title="파일추가하기"
                        >
                            파일 선택
                        </Box>
                    </label>
                </Box>
            </Grid>
            <AlertModal open={alertModalOpen} close={handleAlertModalClose} text={alertText} title={"안내"} />
        </Grid>
    );
};

export default MultipleFileUploader;
