import React, { lazy } from "react";
import { AuthApi } from "api/auth/auth";
import Loadable from "components/Loadable";
import Pages from "store/pages";

const Login = Loadable(lazy(() => import("./login")));
const Join = Loadable(lazy(() => import("./join")));
// const Register = Loadable(lazy(() => import("views/pages/auth/register")));
const Find = Loadable(lazy(() => import("views/pages/auth/find")));
const JoinComplete = Loadable(lazy(() => import("views/pages/auth/joinComplete")));
const FindPassword = Loadable(lazy(() => import("views/pages/auth/findPassword")));
// const Join = Loadable(lazy(() => import("views/pages/auth/join")));

const AuthConfig = {
    routes: [
        {
            path: Pages.LOGIN,
            element: <Login />
        },
        {
            path: Pages.JOIN,
            element: <Join />
        },
        {
            path: Pages.JOIN_COMPLETE,
            element: <JoinComplete />
        },
        // {
        //     path: Pages.REGISTER,
        //     element: <Register />
        // },
        {
            path: Pages.FIND,
            element: <Find />
        }
    ],
    API: AuthApi
};

export default AuthConfig;
