const Pages = {
    //COMMON
    MAIN: "/", //메인페이지

    LOGIN: "/login", //로그인
    LOGOUT: "/logout",
    ERROR: "/error", //로그인

    ABOUT: "/about",

    // COSTUMER SERVICE
    COMMUNITY_NEWS: "/community/news",
    COMMUNITY_NEWS_DETAIL: "/community/news/:code",
    COMMUNITY_EVENT: "/community/event",
    COMMUNITY_EVENT_DETAIL: "/community/event/:code",
    COMMUNITY_CONSULTATION: "/vip/consultation",

    COMMUNITY_REQUEST: "/community/request",

    COMMUNITY_CONTACT: "/community/contact/create",
    COMMUNITY_CONTACT_LIST: "/community/contact",
    COMMUNITY_CONTACT_DETAIL: "/community/contact/detail/:code",

    COMMUNITY_QNA: "/community/qna",
    COMMUNITY_QNA_CREATE: "/community/qna/create",
    COMMUNITY_QNA_DETAIL: "/community/qna/:code",
    COMMUNITY_QNA_UPDATE: "/community/qna/update",

    COMMUNITY_REVIEW: "/community/review",
    COMMUNITY_REVIEW_DETAIL: "/community/review/:code",
    COMMUNITY_REVIEW_CREATE: "/community/review/create",

    COMMUNITY_STAFF: "/community/staff",
    COMMUNITY_STAFF_DETAIL: "/community/staff/:code",

    APPLICATION: "/application",

    JOIN: "/join",
    JOIN_COMPLETE: "/join/complete",
    REGISTER: "/register",
    FIND: "/find/id/password",
    FIND_ID: "/find/id",
    FIND_PASSWORD: "/find/password"
};

export default Pages;
