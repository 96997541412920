import React, {forwardRef} from "react";
import {OutlinedInput} from "@mui/material";

const BusinessRegistrationInput = forwardRef(({value, onChange, defaultValue, ...props}, _) => {
    const numericValue = value?.replace(/\D/g, "");

    const formattedValue =
        numericValue?.slice(0, 3) +
        (numericValue?.length > 3 ? "-" + numericValue?.slice(3, 5) : "") +
        (numericValue?.length > 5 ? "-" + numericValue?.slice(5, 10) : "");

    const handleChange = (e) => {
        const inputValue = e.target.value.replace(/\D/g, "");
        onChange(inputValue);
    };

    return (
        <OutlinedInput
            id={props.name}
            name={props.name}
            placeholder={props?.placeholder || "사업자등록번호를 입력하세요."}
            value={formattedValue}
            defaultValue={defaultValue}
            maxLength={props?.maxLength || "50"}
            onChange={handleChange}
            sx={{marginBottom: 0, maxWidth: "200px"}}
            {...props}
        />
    )
});

export default BusinessRegistrationInput;

