import { createApi } from "@reduxjs/toolkit/query/react";
import { Api } from "../index";
import { URL } from "store/urls";

export const CommunityApi = createApi({
    reducerPath: "CommunityApi",
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ["Community"],
    endpoints: (build) => ({
        noticeList: build.query({
            query: (params) => `${URL.NOTICE_LIST}?${params}`,
            providesTags: ["LIST"]
        }),
        noticeDetail: build.query({
            query: (params) => `${URL.NOTICE_DETAIL}?${params}`,
            providesTags: ["NOTICE_DETAIL"]
        }),
        faqList: build.query({
            query: (params) => `${URL.FAQ_LIST}?${params}`,
            providesTags: ["FAQ_LIST"]
        }),
        newsList: build.query({
            query: (params) => `${URL.NEWS_LIST}?${params}`,
            providesTags: ["NEWS_LIST"]
        }),
        eventList: build.query({
            query: (params) => `${URL.EVENT_LIST}?${params}`,
            providesTags: ["EVENT_LIST"]
        }),
        portfolioList: build.query({
            query: (params) => `${URL.PORTFOLIO_LIST}?${params}`,
            providesTags: ["PORTFOLIO_LIST"]
        }),
        newsDetail: build.query({
            query: (params) => `${URL.NEWS_DETAIL}?${params}`,
            providesTags: ["NEWS_DETAIL"]
        }),
        portfolioDetail: build.query({
            query: (params) => `${URL.PORTFOLIO_DETAIL}?${params}`,
            providesTags: ["PORTFOLIO_DETAIL"]
        }),
        eventDetail: build.query({
            query: (params) => `${URL.EVENT_DETAIL}?${params}`,
            providesTags: ["EVENT_DETAIL"]
        }),
        contactCreate: build.mutation({
            query: (body) => ({
                url: `${URL.CONTACT}`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ["CONTACT_LIST"]
        }),
        dynamicPageData: build.query({
            query: (params) => `${URL.DYNAMIC_PAGE}/${params}`,
            providesTags: ["PAGE"]
        })
    })
});

export const {
    usePortfolioListQuery,
    usePortfolioDetailQuery,
    useFaqListQuery,
    useDynamicPageDataQuery,
    useContactCreateMutation,
    useEventListQuery,
    useEventDetailQuery,
    useNewsListQuery,
    useNewsDetailQuery,
} = CommunityApi;
