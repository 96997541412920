import { MainConfig, AuthConfig, CommunityConfig, ApplicationConfig } from "views/pages";
import { CommonCodeFilterApi } from "api/commonCode";
import { BannerApi } from "api/main/banner";
import { UserAddressApi } from "api/userAddress/index";
import { CertCategoryApi } from "api/cert/certCategory";
import { PaymentApi } from "api/payment/payment";

// ==============================|| COMBINE REDUCER ||============================== //

const Reducer = {
    [MainConfig.API.reducerPath]: MainConfig.API.reducer,
    [AuthConfig.API.reducerPath]: AuthConfig.API.reducer,
    [CommunityConfig.API.reducerPath]: CommunityConfig.API.reducer,
    [CommonCodeFilterApi.reducerPath]: CommonCodeFilterApi.reducer,
    [BannerApi.reducerPath]: BannerApi.reducer,
    [CertCategoryApi.reducerPath]: CertCategoryApi.reducer,
    [UserAddressApi.reducerPath]: UserAddressApi.reducer,
    [ApplicationConfig.API.reducerPath]: ApplicationConfig.API.reducer,
    [PaymentApi.reducerPath]: PaymentApi.reducer
};

export default Reducer;
