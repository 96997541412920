import { createApi } from "@reduxjs/toolkit/query/react";
import { Api } from "../index";
import { URL } from "store/urls";

export const MainApi = createApi({
    reducerPath: "MainApi",
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ["Mains"],
    endpoints: (build) => ({
        getMenus: build.query({
            query: () => `${URL.MENU_LIST}`,
            providesTags: ["MENU_LIST"]
        }),
        getSiteInfo: build.query({
            query: () => `${URL.SITE_INFO}`,
            providesTags: ["SITE_INFO"]
        }),
        getMainInfo: build.query({
            query: () => `${URL.MAIN_INFO}`,
            providesTags: ["MAIN_INFO"]
        }),
        getCurrentTime: build.query({
            query: () => `${URL.CURRENT_TIME}`,
            providesTags: ["CURRENT_TIME"]
        }),
        getSiteSettingsInfo: build.query({
            query: () => `${URL.SITE_SETTINGS_INFO}`,
            providesTags: ["SITE_SETTINGS_INFO"]
        })
    })
});

export const { useGetMenusQuery, useGetSiteInfoQuery, useGetMainInfoQuery, useGetCurrentTimeQuery, useGetSiteSettingsInfoQuery } = MainApi;
