import React from "react";
import { useLocation } from "react-router-dom";

const Steps = ({ step }) => {
    const location = useLocation();

    const stepBox = [
        {
            stepProps: "step_01",
            stepNum: "Step, 01",
            stepTit: "가입등록",
            stepsItems: [
                { path: "/join", label: "약관동의" },
                { path: "/application", label: "기업등록" },
                { path: "/financial", label: "재무/일반 자료 입력" }
            ]
        },
        {
            stepProps: "step_02",
            stepNum: "Step, 02",
            stepTit: "자가진단\n입력",
            stepsItems: [
                { path: "/selfdiagnosis", label: "활동지표 확인" },
                { path: "/selfresult", label: "예비판정 결과" }
            ]
        },
        {
            stepProps: "step_03",
            stepNum: "Step, 03",
            stepTit: "1단계 평가\n제출자료 입력",
            stepsItems: [
                { path: "/evaluation", label: "1단계 평가자료 제출" },
                { path: "/paying", label: "평가 신청 결제하기" }
            ]
        },
        {
            stepProps: "step_04",
            stepNum: "Step, 04",
            stepTit: "2단계\n적격성 평가",
            stepsItems: [
                { path: "/eligibility", label: "2단계 평가자료 제출" },
                // { path: "/eligibility/pay", label: "평가 신청 결제하기" },
                { path: "/certificate", label: "인증완료 및 인증서 발급" }
            ]
        }
    ];

    return (
        <div className="steps">
            <div className="steps_box">
                {stepBox.map((item, i) => (
                    <div className={`steps_box_conts ${step === item.stepProps ? "active" : ""}`} key={i}>
                        <h3>{item.stepNum}</h3>
                        <span>line</span>
                        <p>{item.stepTit}</p>
                    </div>
                ))}
            </div>

            {stepBox
                .filter((item) => item.stepProps === step)
                .map((item, i) => (
                    <div className="steps_item" key={i}>
                        {item.stepsItems.map((subItem, j) => (
                            <div className={`steps_item_cont ${location.pathname === subItem.path ? "active" : ""}`} key={j}>
                                {subItem.label}
                            </div>
                        ))}
                    </div>
                ))}
        </div>
    );
};

export default Steps;
